<template>
  <div class="event-box">
    <div class="list-container-body-head">Offer status</div>
    <div class="event-body" >
      <div v-for="status in offerStatuses"
           class="offer-status__check">
        <input
          type="checkbox"
          :id="status.id"
          :checked="isChecked(offer, status.id)"
          class="filled-in"
          :disabled="isDisabled(status, offerStatus)"
          >
        <label :for="status.id">{{status.name}}</label>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import RolesHelper from '../helpers/RolesHelper'
  import {OFFER_STATUSES} from '../helpers/Enums'
  export default {
    data () {
      return {
        OFFER_STATUSES: OFFER_STATUSES
      }
    },
    props: {
      offer: Object,
      offerStatus: Object,
      offerId: Number
    },
    mixins: [RolesHelper],
    computed: {
      ...mapGetters({
        offerStatuses: 'offerStatuses'
      })
    },
    watch: {},
    methods: {
      ...mapActions(['fetchStatuses']),

      isChecked (offer, statusId) {
        return statusId === offer.status.id
      },
      isDisabled (status, offerStatus) {
        return true
      }
    },
    mounted () {
      this.fetchStatuses()
    }
  }
</script>
