<template>
  <div>

    <preloader/>

    <div>
      <header class="header clearfix">
        <div class="container">
          <div class="logo"><router-link to="/"><img src="../../assets/images/logo.svg" alt=""></router-link></div>
          <div class="sing-in"><router-link :to="{name: 'signIn'}"><app-button title="Sign in"/></router-link></div>
        </div>
      </header>
      <section class="forgot-password">
        <div class="container">
          <div class="heading-section align-center"><span>Reset Password</span></div>
          <div class="registration-form">
            <div class="form-box-over">
              <form action="#" class="js_validate">
                <div v-if="token">
                  <input name="email" type="hidden" v-model="email">
                  <input name="token" type="hidden" v-model="token">
                  <div :data-error="errors.first('password')"
                       :class="[this.errorClass('password'), 'input-field', 'required-field']">
                    <div class="label-field">New Password</div>
                    <div class="label-area">
                      <input
                        type="password"
                        name="password"
                        v-model="password"
                        v-validate="'required|min:8'"
                        data-vv-validate-on="submit">
                    </div>
                  </div>
                </div>

                <div v-else :data-error="errors.first('Email')"
                     :class="[this.errorClass('Email'), 'input-field', 'required-field']">
                  <div class="label-field">Email</div>
                  <div class="label-area">
                    <input
                      type="email"
                      name="Email"
                      v-model="email"
                      v-validate="'required|email'"
                      data-vv-validate-on="submit">
                  </div>
                </div>
                <app-button title="Submit" :onClick="submit"/>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div class="above-footer"></div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import Preloader from '../../components/layout/Preloader.vue'
  import Button from '../../components/Button.vue'
  export default {
    data () {
      return {
        email: '',
        token: '',
        password: ''
      }
    },
    mounted () {
      this.email = this.$route.query.email ? this.$route.query.email : ''
      this.token = this.$route.params.token
    },
    components: {
      'preloader': Preloader,
      'app-button': Button
    },
    computed: mapGetters(['authError']),
    methods: {
      ...mapActions(['resetPassword', 'changeForgottenPassword']),

      submit () {
        this.$validator.validateAll().then((result) => {
          if (!result) return
          if (this.email && this.token) {
            this.changeForgottenPassword({email: this.email, token: this.token, password: this.password})
          } else {
            this.resetPassword(this.email)
          }
        })
      },

      errorClass (field) {
        return {
          error: this.errors.has(field)
        }
      }
    },
    beforeDestroy () {
      this.email = ''
      this.token = ''
      this.password = ''
      this.errors = []
    }
  }
</script>
