<template>
  <div class="wrapper-inner wrapper-inner--grey wrapper-inner--freelancer">
    <div class="row">
      <div class="col l3 s12 right">
        <div class="sidebar-filter filter-box">
          <div class="filter-box__head clearfix"><span>Categories</span>
            <a class="back right" @click="filters.categories = []" :class="{ 'inactive': this.filters.categories.length === 0 }">
            <div class="reset"><span class="icon-ico-38-close"></span>Reset</div></a>
          </div>
          <div class="filter-box__body">
            <form action="#">
              <div class="sidebar-box-group">
                <div v-for="category in allCategories" class="check-box">
                  <input type="checkbox" :id="'category-' + category.id" class="filled-in" :value="category.id" v-model="filters.categories">
                  <label :for="'category-' + category.id">{{category.name}}</label>
                </div>
              </div>
              <app-button title="Apply All" :onClick="checkAllCategories" cssClass="btn--white"/>
            </form>
          </div>
        </div>
      </div>
      <div class="col l9 s12">
        <div class="filter-box">
          <div class="filter-box__head clearfix">
            <span>Filter list</span>
            <a class="back right" @click="resetFilters" :class="{inactive: !isResetActive}">
              <div class="reset"><span class="icon-ico-38-close"></span>Reset</div>
            </a>
          </div>
          <div class="filter-box__body">
            <form action="#">
              <div class="row">
                <div class="col l6 s12">
                  <div data-error="Required field" class="input-field search-field"><span class="label">Offer name</span>
                    <input type="text" v-model="filters.name" placeholder="Search offer title here...">
                  </div>
                </div>
                <div class="col l3 s12">
                  <div data-error="Required field" class="input-field input-field--calendar"><span class="label">Date</span>
                    <datepicker v-model="filters.date" class="datepicker" name="date"/>
                  </div>
                </div>
                <div class="col l3 s12">
                  <div class="input-field"><span class="label">Location</span>
                    <location-input
                      @change="setLocationFilter"
                      :value="location"
                      name="Location"
                    />
                  </div>
                </div>
                <div class="col l3 s12">
                  <div class="input-field"><span class="label">Offer type</span>
                    <app-select
                      name="offerType"
                      v-model="filters.offer_type_id"
                    >
                      <option value="" selected>All offer types</option>
                      <option v-for="type in offerTypes" :value="type.id">{{type.name}}</option>
                    </app-select>
                  </div>
                </div>
                <div class="col l3 s12">
                  <div class="input-field "><span class="label">Min price</span>
                    <div :data-error="errors.first('Min price')" :class="[this.errorClass('Min price'), 'input-field required-field']">
                      <input type="number" min="1" name="Min price" step="1" v-model="filters.price.from" v-validate="'min_value:1'" />
                    </div>
                  </div>
                </div>
                <div class="col l3 s12">
                  <div class="input-field"><span class="label">Max price</span>
                    <div :data-error="errors.first('Max price')" :class="[this.errorClass('Max price'), 'input-field required-field']">
                      <input type="number" :min="maxPriceMinVal" name="Max price" step="1" v-model="filters.price.to" v-validate="'min_value:1'" @change="checkValue()" />
                    </div>
                  </div>
                </div>
                <div class="col l7 s12">
                  <div v-if="profileInfo.role.id !== 3" class="check-box-group"><span class="label">Role</span>
                    <div class="check-box">
                      <input id="check1" type="checkbox" class="filled-in" value="2" v-model="filters.offer_profile_id">
                      <label for="check1">Paralegal</label>
                    </div>
                    <div class="check-box">
                      <input id="check2" type="checkbox" class="filled-in" value="3" v-model="filters.offer_profile_id">
                      <label for="check2">Stenographer</label>
                    </div>
                    <div class="check-box">
                      <input id="check3" type="checkbox" class="filled-in" value="4" v-model="filters.offer_profile_id">
                      <label for="check3">Expert witness</label>
                    </div>
                  </div>
                  <!--<div class="check-box-group"><span class="label">Sort</span>-->
                    <!--<div class="check-box">-->
                      <!--<input id="check4" type="radio" class="filled-in">-->
                      <!--<label for="check4">By rating</label>-->
                    <!--</div>-->
                  <!--</div>-->
                </div>
                <div class="col l2 s12 align-right">
                  <app-button v-if="!this.isCompany(profileInfo)" cssClass="btn--white" title="Fast Offers" :onClick="getFiltersFromProfile" />
                </div>
                <div class="col l2 s12 align-right">
                  <app-button cssClass="btn--white" title="Filter" :onClick="applyFilters" />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="list-container list-container--board">
          <div class="list-container__body">
            <div class="list-container-body-head">Job Board</div>
            <div v-for="offer in allOpenOffers" class="offer-item">
              <div class="offer-item__name">
                {{offer.name}}
              </div>
              <div class="offer-item__detail">
                <ul>
                  <li><span class="icon-ico-40-date"></span>{{formatDate(offer.date)}}</li>
                  <li><span class="icon-ico-42-money"></span>$ {{offer.price}}</li>
                  <li>
                    <router-link :to="'/companies/' + offer.company.id">
                    <span class="icon-company">
                      <img :src="getStorageUrl() + offer.company.image_url" alt="">
                    </span>
                      {{offer.company.name}}
                    </router-link>
                  </li>
                  <li><span class="icon-ico-53-rate"></span>{{offer.company.rating || 'N/A' }}</li>
                </ul>
              </div>
              <div class="offer-item__info">
                <p>{{offer.description}}</p>
                <ul>
                  <li><span>Type:</span>{{offer.offerType.name}}</li>
                  <li><span class="icon-ico-43-category"></span>{{offer.category.name}}</li>
                  <li v-if="offer.location"><span class="icon-ico-44-location"></span>{{offer.location.formatted_address}}</li>
                  <li v-if="offer.conference_link">
                    <span>Conference link</span>
                    <template v-for="(part, index) in parseConferenceLink(offer.conference_link)">
                      <a v-if="part.isUrl" :href="part.text" target="_blank" style="margin-right: 10px; color: #575757">{{ part.text }}</a>
                      <span v-else style="color: #575757">{{ part.text }}</span>
                      <span v-if="index < parseConferenceLink(offer.conference_link).length - 1" style="color: #9d9d9d">|</span>
                    </template>
                  </li>
                  <li v-if="offer.offerProfile"><span>Freelancer type</span>{{offer.offerProfile.name}}</li>
                </ul>
                <router-link :to="getOfferUrl(offer)" class="offer-item__button"><app-button cssClass="btn--default" title="View" :slideOnHover="false"/></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-container__bottom">
      <pagination :current-page="currentPage"
                  :total-items="offersTotal"
                  :itemsPerPage="perPage"
                  @page-changed="pageChanged">
      </pagination>
    </div>
  </div>

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import Select from '../../components/Select.vue'
  import Button from '../../components/Button.vue'
  import Datepicker from '../../components/Datepicker.vue'
  import LocationInput from '../../components/LocationInput.vue'
  import Pagination from '../../components/Pagination.vue'
  import ImageHelper from '../../helpers/ImageHelper'
  import RolesHelper from '../../helpers/RolesHelper'
  import DateHelper from '../../helpers/DateHelper'
  import LinkHelper from '@/helpers/LinkHelper';

  export default {
    data () {
      return {
        location: '',
        filters: {
          name: '',
          date: '',
          categories: [],
          location: '',
          offer_profile_id: [],
          price: {from: '1', to: null},
          offer_type_id: ''
        },
        perPage: 10,
        currentPage: 1
      }
    },
    mixins: [ImageHelper, RolesHelper, DateHelper, LinkHelper],
    computed: {
      ...mapGetters(['states', 'locations', 'allCategories', 'allOpenOffers', 'profileInfo', 'offersTotal', 'offerTypes']),
      locationsByState () {
        if (this.filters.state_id) {
          return this.locations.filter(location => {
            return location.state.id === parseInt(this.filters.state_id, 10)
          })
        } else {
          return this.locations
        }
      },
      maxPriceMinVal () {
        return this.filters.price.from
      },
      isResetActive () {
        return this.location !== '' ||
          this.filters.name !== '' ||
          this.filters.date !== '' ||
          this.filters.location !== '' ||
          this.filters.offer_profile_id.length > 0 ||
          this.filters.price.from !== '1' ||
          this.filters.price.to !== null ||
          this.filters.offer_type_id !== ''
      }
    },
    methods: {
      ...mapActions(['fetchAllOpenOffers', 'fetchStates', 'fetchLocations', 'fetchCategories', 'fetchOfferTypes']),
      getOfferUrl (offer) {
        return this.isCompany(this.profileInfo) && this.profileInfo.id === offer.company.id
          ? '/myCalendar/myJobOffers/' + offer.id
          : '/jobBoard/' + offer.id
      },
      applyFilters () {
        this.$validator.validateAll().then((result) => {
          if (!result) return
          this.fetchAllOpenOffers(this.filters)
        }).catch(() => {
          console.log(this.errors.all())
        })
      },
      errorClass (field) {
        return {
          error: this.errors.has(field)
        }
      },
      resetFilters () {
        if (!this.isResetActive) return
        this.location = ''
        this.filters = {
          name: '',
          date: '',
          categories: this.filters?.categories,
          location: '',
          offer_profile_id: [],
          price: {from: '1', to: null},
          offer_type_id: ''
        }
        this.fetchAllOpenOffers(this.filters)
      },
      checkAllCategories () {
        this.filters.categories = []
        this.allCategories.forEach(category => {
          this.filters.categories.push(category.id)
        })
      },
      getFiltersFromProfile () {
        const filtersFromProfile = {
          state_id: this.profileInfo.state ? this.profileInfo.state.id : null,
          location_id: this.profileInfo.location ? this.profileInfo.location.id : null,
          location: this.profileInfo.location ? this.profileInfo.location.formatted_address : null,
          categories: this.profileInfo.categories.map(category => category.id)
        }

        this.filters = Object.assign({}, this.filters, filtersFromProfile)
        this.fetchAllOpenOffers(this.filters)
      },
      setLocationFilter (res) {
        this.$set(this, 'filters', {
          ...this.filters,
          location: res.formatted_address,
        })
        this.location = res.formatted_address
      },
      pageChanged (page) {
        this.currentPage = page
        this.fetchAllOpenOffers({...this.filters, page})
      },
      checkValue () {
        if (+this.filters.price.from > +this.filters.price.to && this.filters.price.to) {
          this.filters.price.to = null
        }
      }
    },
    components: {
      'app-button': Button,
      'app-select': Select,
      'datepicker': Datepicker,
      'location-input': LocationInput,
      'pagination': Pagination
    },
    mounted () {
      this.fetchAllOpenOffers()
      this.fetchCategories()
      this.fetchOfferTypes()
    },
    watch: {
      maxPriceMinVal: function (value) {
        if (+value < 0) {
          this.filters.price.from = 1
        }
        if (+value > +this.filters.price.to && this.filters.price.to) {
          this.filters.price.to = value
        }
      }
    }
  }
</script>

<style>
  .datepicker, .pac-item {
    cursor: pointer;
  }
</style>
