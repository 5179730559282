<template>
  <div class="wrapper" :class="{ logged: isAuthenticated }">
    <app-header v-if="isAuthenticated"/>

    <section :class="['general-container', { 'registration': isRegistrationPage, 'general-container--grey': isDashboardPage }]">
      <general-navigation v-if="isAuthenticated"/>
      <div :class="[isRegistrationPage ? '' : 'container']">
        <router-view :key="$route.fullPath"></router-view>
      </div>
      <app-store-download-button :link="'https://apple.co/3HUjhhQ'" :shouldShow="shouldShowAppStoreButton"/>
    </section>
    <app-footer/>
    <router-link v-if="shouldShowOfferButton" :to="{name: 'offerCreation'}" class="create-offer-button">+</router-link>
  </div>
</template>

<script>
import AppStoreDownloadButton from '@/components/AppStoreDownloadButton.vue';
import Footer from '../../components/layout/Footer.vue'
import GeneralNavigation from '../../components/layout/GeneralNavigation.vue'
import Header from '../../components/layout/Header.vue'

export default {
  components: {
    'app-store-download-button': AppStoreDownloadButton,
    'app-header': Header,
    'app-footer': Footer,
    'general-navigation': GeneralNavigation
  },
  props: ['isAuthenticated', 'isFreelancer'],
  computed: {
    isRegistrationPage() {
      const routeList = ['registration', 'registrationCompany', 'registrationFreelancer'];
      return routeList.includes(this.$route.name);
    },
    shouldShowAppStoreButton() {
      const routeList = ['registration', 'registrationCompany', 'registrationFreelancer', 'signIn'];
      return routeList.includes(this.$route.name);
    },
    shouldShowOfferButton() {
      return this.isAuthenticated && !this.isFreelancer
    },
    isDashboardPage() {
      return this.$route.name === 'dashboard';
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/var_and_mixins";

.general-container {
  &.registration {
    padding: 0;
  }

  &--grey {
    background-color: $bg;
  }
}

.create-offer-button {
  align-items: center;
  background: linear-gradient(90deg, #cda61f 0%, #f2e35a 100%);
  border-radius: 50%;
  top: 115px;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 25px;
  height: 30px;
  line-height: 30px;
  justify-content: center;
  right: 20px;
  position: fixed;
  width: 30px;
  z-index: 50;

  &:hover, &:focus {
    color: #000000;
  }
}
</style>

