<template src="./Info.html"></template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import SignaturePad from 'signature_pad'
  import Button from '../../components/Button.vue'
  import Select from '../../components/Select.vue'
  import SignatureTool from '../../components/SignatureTool.vue'
  import LocationInput from '../../components/LocationInput.vue'
  import ConfigHelper from '../../helpers/ConfigHelper'
  import ImageHelper from '../../helpers/ImageHelper'
  import RolesHelper from '../../helpers/RolesHelper'
  import BirthdayPicker from '@/components/BirthdayPicker.vue';
  export default {
    data () {
      return {
        newSkill: '',
        isEditing: false,
        addingSkills: false,
        storageUrl: ConfigHelper.get('storageUrl'),
        newLawyerId: '',
        newLawyerIdState: null,
        addingSignature: false,
        signatureTool: null,
        location: null,
        locationEdit: false,
        profileForm: {
          name: '',
          lastname: '',
          location: null,
          date_of_birth: null,
          phonenumber: '',
          paypal_email: '',
        }
      }
    },
    computed: {
      ...mapGetters(['profileInfo', 'states', 'locations', 'allCategories', 'isFreelancer'])
    },
    mixins: [ImageHelper, RolesHelper],
    methods: {
      ...mapActions(['logout', 'fetchProfileInfo', 'fetchLocations', 'updateProfileInfo', 'addProfileCategory',
        'removeProfileCategory', 'addPersonalSkill', 'removePersonalSkill', 'updateProfileImage', 'updateProfileSignature']),
      update () {
        this.$validator.validateAll('ProfileInfo').then(valid => {
          if (valid) {
            this.updateProfileInfo(this.profileForm)
          }
          // console.log(this.errors.all())
        });
      },
      fieldEditStart (e) {
        const inputToEdit = window.$(e.target).siblings('.label-area').find('input')
        inputToEdit.prop('readonly', false)
        this.$nextTick(() => {
          inputToEdit.focus()
        })
      },
      fieldEditStop (e) {
        if (!e.target.readOnly) e.target.readOnly = true
      },
      locationEditStart (e) {
        this.locationEdit = true
        const inputToEdit = window.$(e.target).siblings('.label-area').find('input')
        this.$nextTick(() => {
          inputToEdit.focus()
        })
      },
      showModal () {
        window.$('#add_lawyer').modal({
          ready () {
            (window.$('.modal-overlay').detach().appendTo('.wrapper'))
          }
        })
      },
      closeModal () {
        window.$('.modal').modal('close')
        this.newLawyerId = ''
        this.newLawyerIdState = null
      },
      addCategory (category) {
        this.profileInfo.categories.push(category)
        this.addProfileCategory(category.id)
      },
      removeCategory (category) {
        const categories = [...this.profileInfo.categories].filter(c => c.id !== category.id)
        this.$set(this.profileInfo, 'categories', categories)
        this.removeProfileCategory(category.id)
      },
      isCategorySelected (category) {
        return this.profileInfo.categories.find(c => c.id === category.id)
      },
      addSkill () {
        this.addingSkills = false
        if (this.newSkill) {
          this.addPersonalSkill(this.newSkill)
          this.newSkill = ''
        }
      },
      errorClass (field) {
        return {
          error: this.errors.has(field)
        }
      },
      uploadProfileImage (e) {
        let image = new FormData()
        image.append('image', e.target.files[0])
        this.updateProfileImage(image)
      },
      addLawyerId () {
        this.$validator.validateAll('LawyerInfo').then((result) => {
          if (!result) return
          this.profileInfo.law_id.push(`${this.newLawyerId} ${this.newLawyerIdState}`)
          this.updateProfileInfo()

          window.$('.modal').modal('close')
          this.newLawyerId = ''
          this.newLawyerIdState = null
        })
      },
      removeLawyerId (index) {
        this.profileInfo.law_id.splice(index, 1)
        this.updateProfileInfo()
      },
      saveSignatureFromFile (e) {
        let signature = new FormData()
        signature.append('signature', e.target.files[0])
        this.updateProfileSignature(signature)
      },
      initSignatureTool () {
        this.addingSignature = true
        const canvas = document.querySelector('#signature-tool')
        this.signatureTool = new SignaturePad(canvas)
      },
      saveSignatureFromTool () {
        const dataURI = this.signatureTool.toDataURL()
        let signature = new FormData()
        signature.append('signature', this.dataURItoBlob(dataURI))
        this.updateProfileSignature(signature)
        this.addingSignature = false
      },
      clearSignature () {
        this.signatureTool.clear()
      },
      cancelSignature () {
        this.addingSignature = false
      },
      setLocationData (res) {
        this.$set(this, 'profileForm', {
          ...this.profileForm,
          location: res,
        })
        this.locationEdit = false
      }
    },
    components: {
      'app-button': Button,
      'app-select': Select,
      'birthday-picker': BirthdayPicker,
      'signature-tool': SignatureTool,
      'location-input': LocationInput
    },
    mounted () {
      this.$set(this, 'profileForm', {
        name: this.profileInfo.name,
        lastname: this.profileInfo.lastname,
        location: this.profileInfo.location,
        date_of_birth: this.profileInfo.date_of_birth,
        phonenumber: this.profileInfo.phonenumber,
        paypal_email: this.profileInfo.paypal_email,
      })
    }
  }
</script>

<style lang="scss">
  .add-skill-block {
    position: relative;
  }

  .add-skill-block span {
    position: absolute;
    right: 10px;
    top: 8px;
  }

  .delete-icon {
    transform: rotate(45deg);
    display: inline-block;
  }
  #profileImageUpload {
    height: 0;
    width: 0;
    opacity: 0;
  }

  #profileImage {
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-size: cover;
  }

  .change-avatrar {
    cursor: pointer;
  }

  #signature-image {
    width: 350px;
    height: 200px;
    overflow: hidden;
    border: 1px dashed #c6c6c6;
    margin-bottom: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  #signature-controls {
    display: flex;
    li {
      margin-right: 10px;
    }
  }

  .add-lawyer {
    margin-left: 0 !important;
  }

  .company-info .avatar-box {
    margin-top: -15px !important;
    @media (max-width: 992px) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .non-lawyer-select {
    /*width: 70%;*/
    .caret {
      width: 10px !important;
      font-size: 0 !important;
      height: 15px;
      &:before {
        font-size: 5px !important;
      }
    }
  }

  .state-select ul {
    height: 170px;
  }
</style>
