export const ALL_OPEN_OFFERS_FETCH_PROCESSING = 'ALL_OPEN_OFFERS_FETCH_PROCESSING'
export const ALL_OPEN_OFFERS_FETCH_SUCCESS = 'ALL_OPEN_OFFERS_FETCH_SUCCESS'
export const ALL_OPEN_OFFERS_FETCH_ERROR = 'ALL_OPEN_OFFERS_FETCH_ERROR'

export const OFFER_TYPES_FETCH_PROCESSING = 'OFFER_TYPES_FETCH_PROCESSING'
export const OFFER_TYPES_FETCH_SUCCESS = 'OFFER_TYPES_FETCH_SUCCESS'
export const OFFER_TYPES_FETCH_ERROR = 'OFFER_TYPES_FETCH_ERROR'

export const OPEN_OFFER_DETAILS_FETCH_PROCESSING = 'OPEN_OFFER_DETAILS_FETCH_PROCESSING'
export const OPEN_OFFER_DETAILS_FETCH_SUCCESS = 'OPEN_OFFER_DETAILS_FETCH_SUCCESS'
export const OPEN_OFFER_DETAILS_FETCH_ERROR = 'OPEN_OFFER_DETAILS_FETCH_ERROR'
export const OPEN_OFFER_DETAILS_CANCEL = 'OPEN_OFFER_DETAILS_CANCEL'

export const CREATE_OFFER_PROCESSING = 'CREATE_OFFER_PROCESSING'
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS'
export const CREATE_OFFER_ERROR = 'CREATE_OFFER_ERROR'

export const UPDATE_OFFER_PROCESSING = 'UPDATE_OFFER_PROCESSING'
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS'
export const UPDATE_OFFER_ERROR = 'UPDATE_OFFER_ERROR'

export const OFFERS_FOR_COMPANY_FETCH_PROCESSING = 'OFFERS_FOR_COMPANY_FETCH_PROCESSING'
export const OFFERS_FOR_COMPANY_FETCH_SUCCESS = 'OFFERS_FOR_COMPANY_FETCH_SUCCESS'
export const OFFERS_FOR_COMPANY_FETCH_ERROR = 'OFFERS_FOR_COMPANY_FETCH_ERROR'

export const OFFERS_FOR_FREELANCER_FETCH_PROCESSING = 'OFFERS_FOR_FREELANCER_FETCH_PROCESSING'
export const OFFERS_FOR_FREELANCER_FETCH_SUCCESS = 'OFFERS_FOR_FREELANCER_FETCH_SUCCESS'
export const OFFERS_FOR_FREELANCER_FETCH_ERROR = 'OFFERS_FOR_FREELANCER_FETCH_ERROR'

export const SET_OFFER_DATE_FROM_CALENDAR = 'SET_OFFER_DATE_FROM_CALENDAR'

export const SEND_TO_FREELANCER_PROCESSING = 'SEND_TO_FREELANCER_PROCESSING'
export const SEND_TO_FREELANCER_SUCCESS = 'SEND_TO_FREELANCER_SUCCESS'
export const SEND_TO_FREELANCER_ERROR = 'SEND_TO_FREELANCER_ERROR'

export const PLACE_OFFER_BID_PROCESSING = 'PLACE_OFFER_BID_PROCESSING'
export const PLACE_OFFER_BID_SUCCESS = 'PLACE_OFFER_BID_SUCCESS'
export const PLACE_OFFER_BID_ERROR = 'PLACE_OFFER_BID_ERROR'

export const CANCEL_OFFER_BID_PROCESSING = 'CANCEL_OFFER_BID_PROCESSING'
export const CANCEL_OFFER_BID_SUCCESS = 'CANCEL_OFFER_BID_SUCCESS'
export const CANCEL_OFFER_BID_ERROR = 'CANCEL_OFFER_BID_ERROR'

export const ACCEPT_OFFER_BID_PROCESSING = 'ACCEPT_OFFER_BID_PROCESSING'
export const ACCEPT_OFFER_BID_SUCCESS = 'ACCEPT_OFFER_BID_SUCCESS'
export const ACCEPT_OFFER_BID_ERROR = 'ACCEPT_OFFER_BID_ERROR'

export const ASSIGN_OFFER_PROCESSING = 'ASSIGN_OFFER_PROCESSING'
export const ASSIGN_OFFER_SUCCESS = 'ASSIGN_OFFER_SUCCESS'
export const ASSIGN_OFFER_ERROR = 'ASSIGN_OFFER_ERROR'

export const CANCEL_INVITE_PROCESSING = 'CANCEL_INVITE_PROCESSING'
export const CANCEL_INVITE_SUCCESS = 'CANCEL_INVITE_SUCCESS'
export const CANCEL_INVITE_ERROR = 'CANCEL_INVITE_ERROR'

export const RECOMMEND_FREELANCER_PROCESSING = 'RECOMMEND_FREELANCER_PROCESSING'
export const RECOMMEND_FREELANCER_SUCCESS = 'RECOMMEND_FREELANCER_SUCCESS'
export const RECOMMEND_FREELANCER_ERROR = 'RECOMMEND_FREELANCER_ERROR'

export const SEND_TO_JOB_BOARD_PROCESSING = 'SEND_TO_JOB_BOARD_PROCESSING'
export const SEND_TO_JOB_BOARD_SUCCESS = 'SEND_TO_JOB_BOARD_SUCCESS'
export const SEND_TO_JOB_BOARD_ERROR = 'SEND_TO_JOB_BOARD_ERROR'

export const CANCEL_OFFER_PROCESSING = 'CANCEL_OFFER_PROCESSING'
export const CANCEL_OFFER_SUCCESS = 'CANCEL_OFFER_SUCCESS'
export const CANCEL_OFFER_ERROR = 'CANCEL_OFFER_ERROR'

export const DONE_OFFER_PROCESSING = 'DONE_OFFER_PROCESSING'
export const DONE_OFFER_SUCCESS = 'DONE_OFFER_SUCCESS'
export const DONE_OFFER_ERROR = 'DONE_OFFER_ERROR'

export const STATUSES_FETCH_PROCESSING = 'STATUSES_FETCH_PROCESSING'
export const STATUSES_FETCH_SUCCESS = 'STATUSES_FETCH_SUCCESS'
export const STATUSES_FETCH_ERROR = 'STATUSES_FETCH_ERROR'

export const STATUS_CHANGER_RULES_FETCH_PROCESSING = 'STATUS_CHANGER_RULES_FETCH_PROCESSING'
export const STATUS_CHANGER_RULES_FETCH_SUCCESS = 'STATUS_CHANGER_RULES_FETCH_SUCCESS'
export const STATUS_CHANGER_RULES_FETCH_ERROR = 'STATUS_CHANGER_RULES_FETCH_ERROR'

export const ADD_OFFER_COMMENT_PROCESSING = 'ADD_OFFER_COMMENT_PROCESSING'
export const ADD_OFFER_COMMENT_SUCCESS = 'ADD_OFFER_COMMENT_SUCCESS'
export const ADD_OFFER_COMMENT_ERROR = 'ADD_OFFER_COMMENT_ERROR'

export const SET_SELECTED_FREELANCER = 'SET_SELECTED_FREELANCER'
