<template>
  <div>
    <preloader/>
    <div>
      <!--header-->
      <header class="header clearfix">
        <div class="container">
          <div class="logo">
            <router-link to="/"><img src="../../assets/images/logo.svg" alt=""></router-link>
          </div>
          <div class="sing-in">
            <router-link :to="{name: 'signIn'}">
              <app-button title="Sign in"/>
            </router-link>
          </div>
        </div>
      </header>
      <!--header-->
      <section class="registration-form-block">
        <div class="registration-form-block__left">
          <img src="../../assets/images/registration/freelancer.jpg" alt="Registration of freelancer">
        </div>
        <div class="registration-form-block__right">
          <div class="heading-section">REGISTRATION OF FREELANCER</div>

          <router-link :to="{name: 'registration'}">
            <div class="go-back-btn">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 11.5L16.5 1L18.5 3L10 11.5L18.5 20L16.5 22L6 11.5Z"
                    fill="#AF9409"/>
              </svg>
              Back and change account type
            </div>
          </router-link>

          <form action="#" class="js_validate">
            <div class="check-box">
              <div class="label-field"> &nbsp;</div>
              <div class="label-area">
                <div class="radio-wrapper">
                  <div class="radio-item">
                    <input id="check3" type="radio" @click="user.role_id = roles.lawyer" value="lawyer"
                        v-model="freelancerType" name="group2">
                    <label for="check3">Lawyer</label>
                  </div>
                  <div class="radio-item">
                    <input id="check4" type="radio" @click="user.role_id = roles.nonlawyer" value="nonlawyer"
                        v-model="freelancerType" name="group2">
                    <label for="check4">nonLawyer</label>
                  </div>
                </div>
              </div>
            </div>
            <div :data-error="this.authError['email'] || errors.first('email')"
                :class="[this.errorClass('email'), 'input-field', 'required-field']">
              <div class="label-field">Email</div>
              <div class="label-area">
                <input
                    type="email"
                    name="email"
                    v-model="user.email"
                    v-validate="'required|email'"
                    data-vv-validate-on="submit">
              </div>
            </div>
            <div
                :data-error="errors.first('password')"
                :class="[{error: this.errors.has('password')}, 'input-field', 'required-field']">
              <div class="label-field">Password</div>
              <div class="label-area">
                <input-password
                    ref="password"
                    name="password"
                    v-model="user.password"
                    v-validate="'required|min: 8'"
                    data-vv-validate-on="submit"/>
              </div>
            </div>
            <div
                :data-error="errors.first('password_confirmation')"
                :class="[{error: this.errors.has('password_confirmation')}, 'input-field', 'required-field']">
              <div class="label-field">Confirm password</div>
              <div class="label-area">
                <input-password
                    name="password_confirmation"
                    v-model="user.password_confirmation"
                    v-validate="'required|confirmed:password'"
                    data-vv-as="password"
                    data-vv-validate-on="submit"/>
              </div>
            </div>
            <div class="check-box">
              <div class="label-field"> &nbsp;</div>
              <div class="label-area">
                <input
                    id="check-box2"
                    type="checkbox"
                    name="Terms Accepted"
                    v-model="user.termsAccepted"
                    v-validate="'required'"
                    class="filled-in">
                <label for="check-box2">
                  I accept the
                  <RouterLink to="/terms-conditions" target="_blank">Terms of use and Confidentiality agreement</RouterLink>
                </label>
                <br>
                <label
                    :class="[{visible: errors.has('Terms Accepted')},'terms-error']">You must accept the confidentiality agreement to continue.</label>
              </div>
            </div>
            <div class="form-box-margin-left">
              <app-button title="Register" :onClick="submit"/>
            </div>
          </form>
        </div>
      </section>
      <div class="above-footer"></div>
    </div>
  </div>
</template>

<script>
import InputPassword from "@/components/form/InputPassword.vue";
import {mapActions, mapGetters} from 'vuex'
import Button from '../../components/Button.vue'
import Footer from '../../components/layout/Footer.vue'
import Preloader from '../../components/layout/Preloader.vue'
import Select from '../../components/Select.vue'

export default {
  data() {
    return {
      roles: {
        lawyer: 2,
        nonlawyer: 3
      },
      userType: null,
      freelancerType: null,
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        role_id: null,
        termsAccepted: false
      }
    }
  },
  components: {
    InputPassword,
    'app-footer': Footer,
    'preloader': Preloader,
    'app-button': Button,
    'app-select': Select
  },
  computed: mapGetters(['authError']),
  methods: {
    ...mapActions(['register', 'cancelErrors']),

    submit() {
      this.$validator.validateAll().then((result) => {
        if (!result) return
        this.register(this.user)
      })
    },

    errorClass(field) {
      return {
        error: this.authError[field] || this.errors.has(field)
      }
    }
  },
  beforeDestroy() {
    this.cancelErrors()
  }
}
</script>

<style lang="scss">
.radio-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (min-width: 768px) {
    gap: 70px
  }

  & .radio-item {
    width: 100%;
  }
}
</style>
