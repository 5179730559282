const config = {
  apiUrl: process.env.VUE_APP_API_URL + '/api',
  storageUrl: process.env.VUE_APP_API_URL,
  socketUrl: 'https://suitsin.myapp.com.ua:6001',
}

export default class ConfigHelper {
  static get (path) {
    return config.hasOwnProperty(path) ? config[path] : ''
  }
};
