import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

import profile from './profile'
import locations from './locations'
import categories from './categories'
import company from './company'
import freelancer from './freelancer'
import jobOffer from './jobOffer'
import calendarIntegration from './calendarIntegration'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'suitsin-vuex',
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: state => {
    return {
      profile: {
        ...state.profile,
        socketSubActive: false,
      },
    }
  },
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

export default new Vuex.Store({
  modules: {
    profile,
    locations,
    categories,
    company,
    freelancer,
    jobOffer,
    calendarIntegration
  },
  plugins: [vuexLocalStorage.plugin]
})
