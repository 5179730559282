<template>
  <div class="wrapper-inner">
    <div class="general-navigation">
      <ul>
        <router-link tag="li" activeClass="active" :to="{name: 'openOffers'}" exact>
          <span class="icon-ico-21"></span>Job List
        </router-link>
        <router-link tag="li" activeClass="active" :to="{name: 'openOffersMap'}">
          <span class="icon-ico-22"></span>Job Map
        </router-link>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  export default {
    computed: mapGetters(['profileInfo']),
    methods: mapActions(['fetchCategories', 'fetchProfileInfo']),
    mounted () {
      this.fetchCategories()
    }
  }
</script>
