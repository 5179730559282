export default {
  methods: {
    parseConferenceLink(string) {
      const parts = string.split(', ')
      const urlPattern = /^(http:\/\/|https:\/\/)[^\s]+/
      return parts.map(part => {
        const trimmedPart = part.trim()
        const isUrl = urlPattern.test(trimmedPart)
        return { text: trimmedPart, isUrl }
      })
    },
  }
}
