<template>
  <div v-if="freelancer" :class="[this.cssClass, 'event-logo']">
    <div v-if="freelancer.image_url"
      class="avatar friend-icon assigned-freelancer-icon"
      :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"></div>
    <img v-else src="../../assets/images/profilePlaceholder.png" alt=""><span>{{freelancer.name}}<i>{{this.subtext}}</i></span>
  </div>
</template>

<script>
import ImageHelper from '../../helpers/ImageHelper'

export default {
  props: {
    freelancer: Object,
    cssClass: String,
    subtext: {
      type: String,
      default: ''
    }
  },
  mixins: [ImageHelper]
}
</script>

<style>
  .marginLeft {
    margin-left: 25%
  }
</style>

