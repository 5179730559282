<template>
  <div>
    <!--top-section-->
    <section class="top-section clearfix">
      <div style="background: url(/assets/top-bg.png) no-repeat;" class="top-section__image"
          :class="{'top-section__image--push': isAuthenticated}"></div>
      <div class="top-section__video">
<!--        <video src="/assets/1694161.mp4" autoplay muted loop></video>-->
      </div>
      <div class="top-section__caption">
        <div class="top-section__logo"> <img src="/assets/logofull.svg" alt=""></div>
        <div class="line-text">
          <div class="line-text-span top-section__big-text">Putting short-term legal work <span> in an on-demand format </span></div>
        </div>
        <div class="line-text line--mtop">
          <div class="line-text-span">
            SuitsIn is a MarketPlace connecting Attorneys and Freelancers - allowing tomorrow’s job to be completed today.
          </div>
        </div>
        <div class="line-text">
          <div class="line-text-span">
            <router-link :to="{ name: 'registration' }" class="js_start btn btn--small waves-effect waves-light prev-hover">
              JOIN US<span class="bg"></span><span class="btn-tlt">JOIN US</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!--top-section-->

    <!-- wrapper start-->
    <div class="wrapper home-page">
      <!--header-->
      <header class="header">
        <div class="container">
          <div class="logo"><a href="#" @click.prevent="scrollTop()"><img src="/assets/logo.svg" alt=""></a></div>
          <div class="sing-in">
            <!--navigation-->
            <div class="navigation">
              <ul class="section table-of-contents navigation-hover-menu">
                <li><a href="#section-our-way">Our way</a></li>
                <li><a href="#section-advantages">Advantages</a></li>
                <!-- <li><a href="#section3">Our statistics</a></li> -->
                <!-- <li><a href="#section4">mobile app</a></li> -->
              </ul>
            </div>
            <!--navigation-->
            <router-link v-if="isAuthenticated" to="profile/" class="prev-hover btn waves-effect waves-light">
              Profile<span class="bg"></span><span class="btn-tlt">Profile</span>
            </router-link>
            <router-link v-else :to="{ name: 'signIn' }" class="prev-hover btn waves-effect waves-light">
              Sign in<span class="bg"></span><span class="btn-tlt">Sign in</span>
            </router-link>
<!--            <a href="#" @click.prevent="scrollToForm()" class="js_start btn waves-effect waves-light prev-hover">-->
<!--              Get started<span class="bg"></span><span class="btn-tlt">Get started</span>-->
<!--            </a>-->
          </div>
        </div>
      </header>
      <!--header-->

      <!-- section-our-way-->
      <section id="section-our-way" class="section-first section-advantages section scrollspy">
        <div class="container">
          <div class="heading">
            <div class="heading-top"><span>Our Vision</span></div>
            <div class="heading-title"><span>Step by Step</span></div>
          </div>
          <div class="row" data-animation="custom-animation">
            <div class="col l8 m6 s12">
              <div class="image-box image-box--left"><img src="/assets/2.jpg" alt=""></div>
            </div>
            <div class="col l4 m6 s12">
              <div class="advantages-box advantages-box--left">
                <div class="line-text">
                  <div class="line-text-span">
                    <div class="advantages-box__head">Company</div>
                  </div>
                </div>
                <div class="advantages-item" data-text="1">
                  <div class="advantages-icon"><img src="/assets/1.svg" alt=""></div>
                  <div class="advantages-txt">Post the job offer</div>
                </div>
                <div class="advantages-item"  data-text="2">
                  <div class="advantages-icon"><img src="/assets/2.svg" alt=""></div>
                  <div class="advantages-txt">Сonsideration</div>
                </div>
                <div class="advantages-item"  data-text="3">
                  <div class="advantages-icon"><img src="/assets/3.svg" alt=""></div>
                  <div class="advantages-txt">Replying to a request</div>
                </div>
                <div class="advantages-item"  data-text="4">
                  <div class="advantages-icon"><img src="/assets/4.svg" alt=""></div>
                  <div class="advantages-txt">Accept fulfilled job</div>
                </div>
                <RouterLink to="/registration" @click.prevent="scrollToForm()" class="js_start btn btn--small waves-effect waves-light prev-hover">
                  get started<span class="bg"></span><span class="btn-tlt">get started</span>
                </RouterLink>
              </div>
            </div>
          </div>
          <div class="row row--cus" data-animation="custom-animation">
            <div class="col l4 m6 s12">
              <div class="advantages-box advantages-box--right">
                <div class="line-text">
                  <div class="line-text-span">
                    <div class="advantages-box__head">Freelancer</div>
                  </div>
                </div>
                <div class="advantages-item" data-text="1">
                  <div class="advantages-icon"><img src="/assets/1.svg" alt=""></div>
                  <div class="advantages-txt">Search for a job</div>
                </div>
                <div class="advantages-item"  data-text="2">
                  <div class="advantages-icon"><img src="/assets/2.svg" alt=""></div>
                  <div class="advantages-txt">Сonsideration</div>
                </div>
                <div class="advantages-item"  data-text="3">
                  <div class="advantages-icon"><img src="/assets/3.svg" alt=""></div>
                  <div class="advantages-txt">Replying to a request</div>
                </div>
                <div class="advantages-item"  data-text="4">
                  <div class="advantages-icon"><img src="/assets/4.svg" alt=""></div>
                  <div class="advantages-txt">Do the job.  Get paid.  Repeat.</div>
                </div>
                <RouterLink to="/registration" @click.prevent="scrollToForm()" class="js_start btn btn--small waves-effect waves-light prev-hover">
                  get started<span class="bg"></span><span class="btn-tlt">get started</span>
                </RouterLink>
              </div>
            </div>
            <div class="col l8 m6 s12">
              <div class="image-box image-box--right"><img src="/assets/1.jpg" alt=""></div>
            </div>
          </div>
        </div>
      </section>
      <!-- section-our-way-->

      <!-- section-parallax-->
      <section data-animation="custom-animation" class="section-parallax animated">
        <div class="container">
          <div class="parallax-caption">
            <!-- <div class="line-text">
              <div class="line-text-span">a perfect law in your hand Join to us</div>
            </div> -->
            <!--             <div class="line-text">
            <div class="line-text-butt"><a href="#" class="prev-hover btn waves-effect waves-light">

                Create an offer<span class="bg"></span><span class="btn-tlt">Create an offer</span></a></div>
          </div> -->
          </div>
          <div class="parallax-cont"><img src="/assets/town2.jpg" alt="" class="parall-bg"><img src="/assets/man2.png" alt="" class="parall-img"></div>
        </div>
      </section>
      <!-- section-parallax-->

      <!-- section-advantages-->
      <section id="section-advantages"  data-animation="custom-animation" class="section-advantages section-advantages--cus animated section scrollspy">
        <div class="container">
          <div class="heading">
            <div class="heading-title"><span>advantages</span></div>
          </div>
          <div class="row">
            <div class="col l4 m4 s6">
              <div class="adv-it">
                <div class="adv-it__icon">
                  <img src="/assets/5.svg" alt="">
                </div>
                Save time - post a job request or find work within seconds
              </div>
            </div>
            <div class="col l4 m4 s6">
              <div class="adv-it">
                <div class="adv-it__icon">
                  <img src="/assets/6.svg" alt="">
                </div>
                Access a comprehensive job board
              </div>
            </div>
            <div class="col l4 m4 s6">
              <div class="adv-it">
                <div class="adv-it__icon">
                  <img src="/assets/7.svg" alt="">
                </div>
                Winning results in multiple cases
              </div>
            </div>
            <div class="col l4 m4 s6">
              <div class="adv-it">
                <div class="adv-it__icon">
                  <img src="/assets/8.svg" alt="">
                </div>
                Streamlined payments
              </div>
            </div>
            <div class="col l4 m4 s6">
              <div class="adv-it">
                <div class="adv-it__icon">
                  <img src="/assets/9.svg" alt="">
                </div>
                Store and share templates, resumes and any other documents
              </div>
            </div>
            <div class="col l4 m4 s6">
              <div class="adv-it">
                <div class="adv-it__icon">
                  <img src="/assets/10.svg" alt="">
                </div>
                Built by legal professionals for legal professionals
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-store-download-button :link="'https://apple.co/3HUjhhQ'" :shouldShow="true"/>

      <!-- section-advantages-->

      <!--section get started-->
<!--      <section data-animation="custom-animation" class="section-getstartted animated section scrollspy">
        <div class="container">
          <div class="heading">
            <div class="heading-top"><span></span></div>
            <div class="heading-title"><span>Get started</span></div>
          </div>
          <div class="row">
            <div class="col s12">
              <form class="getstartted-form">
                <div :data-error="errors.first('Name')"
                     :class="{'error': errors.has('Name'), 'input-field': true, 'required-field': true}">
                  <div class="over">
                    <div class="label-field">Name</div>
                    <div class="label-area">
                      <input
                        type="text"
                        required
                        name="Name"
                        v-validate="'required'"
                        v-model="getStarted_name"
                        data-vv-validate-on="submit"
                        aria-required="true"
                        aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div :data-error="errors.first('Email')"
                     :class="{'error': errors.has('Email'), 'input-field': true, 'required-field': true}">
                  <div class="over">
                  <div class="label-field">Email</div>
                    <div class="label-area">
                      <input
                        type="email"
                        required
                        name="Email"
                        v-model="getStarted_email"
                        v-validate="'required|email'"
                        data-vv-validate-on="submit"
                        aria-required="true"
                        aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div :data-error="errors.first('Message')"
                     :class="{'error': errors.has('Message'), 'input-field': true, 'required-field': true}">
                  <div class="over">
                    <div class="label-field">Message</div>
                    <div class="label-area">
                      <textarea
                        name="Message"
                        required
                        v-validate="'required'"
                        v-model="getStarted_mess"
                        data-vv-validate-on="submit"
                        class="materialize-textarea"></textarea>
                    </div>
                  </div>
                </div>
                <div class="over">
                  <app-button title="Send" :onClick="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>-->
      <!--section get started-->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Button from '../../components/Button.vue'
import AppStoreDownloadButton from '@/components/AppStoreDownloadButton.vue'
export default {
  data () {
    return {
      getStarted_mess: '',
      getStarted_email: '',
      getStarted_name: ''
    }
  },
  computed: mapGetters(['isAuthenticated', 'profileInfo']),
  components: {
    'app-store-download-button': AppStoreDownloadButton,
    'app-button': Button
  },
  methods: {
    ...mapActions(['getStarted']),
    submit (e) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          console.log(this.errors.all())
          return
        }
        this.getStarted({name: this.getStarted_name, email: this.getStarted_email, message: this.getStarted_mess})
        this.getStarted_name = ''
        this.getStarted_email = ''
        this.getStarted_mess = ''
      })
    },
    scrollTop () {
      window.$('body, html').animate({
        scrollTop: 0
      }, 1000)
    },
    scrollToForm () {
      window.$('body, html').animate({
        scrollTop: window.$('.section-getstartted').offset().top
      }, 500)
    },
    initialize () {
      window.genFunc.appearFunction()
      window.genFunc.pagePreloader()
      window.genFunc.hoverButton()
      window.$('.scrollspy').scrollSpy({
        scrollOffset: -50
      })
      window.$('ul.tabs').tabs()
      window.$(window).on('scroll', window.genFunc.scrollAnimate)
      document.querySelector('.wrapper.home-page').style.marginTop = window.innerHeight + 'px'
      window.$('.create-an-offer-tab__content .tab-item').each(function () {
        let tabHeight = 0
        if (window.$(window).outerWidth() > 1199) {
          if (tabHeight < window.$(this).outerHeight()) {
            tabHeight = window.$(this).outerHeight()
          }
        } else {
          if (window.$(window).outerWidth() < 599) {
            if (tabHeight < window.$(this).outerHeight()) {
              tabHeight = window.$(this).outerHeight() * 4 + 30
            }
          } else {
            if (tabHeight < window.$(this).outerHeight()) {
              tabHeight = window.$(this).outerHeight() * 2 + 30
            }
          }
        }
        window.$('.create-an-offer-tab__content').height(tabHeight)
      })
    }
  },
  mounted () {
    this.initialize()
  },
  beforeDestroy () {
    window.$(window).off('scroll', window.genFunc.scrollAnimate)
  }
}

</script>
