<template>
  <header class="header">
    <div class="container">
      <div class="logo"><router-link to="/profile"><img src="../../assets/images/logo.svg" alt=""></router-link></div>
      <div class="header-actions">
        <notifications></notifications>
        <div class="user-box">
          <a data-activates="dropdown1" class="dropdown-button btn">
            <div class="user-name">{{ profileInfo && profileInfo.name }}</div>
            <div v-if="profileInfo && profileInfo.image_url" class="user-avatar" id="header-avatar"
                :style="{ backgroundImage: 'url(' + storageUrl + profileInfo.image_url + ')' }"></div>
            <div v-else class="user-avatar"></div>
          </a>
          <div id="dropdown1" class="dropdown-content">
            <ul>
              <router-link tag="li" :to="{name: 'profile'}"><a href="">Profile</a></router-link>
              <li><router-link activeClass="active" :to="{name: 'myCalendar'}">My Calendar</router-link></li>
              <li class="divider"></li>
              <li @click="logout"><a href="#">Log out</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <general-loader v-if="isProcessing"/>
  </header>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import ConfigHelper from '../../helpers/ConfigHelper'
  import Notifications from '../Notifications.vue'
  import GeneralLoader from './GeneralLoader.vue'
  export default {
    data () {
      return {
        storageUrl: ConfigHelper.get('storageUrl')
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'profileInfo']),
      isProcessing () {
        const state = this.$store.state
        return state.jobOffer.isProcessing ||
          state.profile.isProcessing ||
          state.freelancer.isProcessing ||
          state.company.isProcessing ||
          state.isNewOfferWait
      }
    },
    methods: mapActions(['logout']),
    components: {
      'notifications': Notifications,
      'general-loader': GeneralLoader
    },
    mounted () {
      window.$('.dropdown-button').dropdown({
        outDuration: 301
      })
    }
  }
</script>

<style>
  #header-avatar {
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    width: 49px;
    height: 49px;
    background-size: cover;
  }
</style>
