import axios from 'axios'
import qs from 'qs'

import router from '../../router'
import ConfigHelper from '../../helpers/ConfigHelper'
import FormErrorHelper from '@/helpers/FormErrorHelper';
import * as types from './mutationTypes'
import {OFFER_STATUSES} from '@/helpers/Enums'

const BASE_URL = ConfigHelper.get('apiUrl')
const OFFERS_URL = BASE_URL + '/offers'
const OFFER_DETAILS_URL = (id) => `${OFFERS_URL}/${id}`
const OFFER_TYPES_URL = BASE_URL + '/offer_types'
const OFFERS_FOR_COMPANY_URL = (id) => `${BASE_URL}/companies/${id}/offers`
const POSSIBLE_ASSIGNED_OFFERS_FOR_FREELANCER_URL = (id) => `${BASE_URL}/freelancers/${id}/possible_and_assigned_offers`
// const POSSIBLE_OFFERS_FOR_FREELANCER_URL = (id) => `${BASE_URL}/freelancers/${id}/possible_offers`
// const ASSIGNED_OFFERS_FOR_FREELANCER_URL = (id) => `${BASE_URL}/freelancers/${id}/assigned_offers`
const SEND_TO_FREELANCER_URL = (id) => `${BASE_URL}/offers/${id}/invite_freelancer`
const PLACE_OFFER_BID_URL = (id) => `${BASE_URL}/offers/${id}/bid`
const CANCEL_OFFER_BID_URL = (id) => `${BASE_URL}/offers/${id}/cancel_bid`
const ACCEPT_OFFER_BID_URL = (id, bidId) => `${BASE_URL}/offers/${id}/accept_bid/${bidId}`
const ASSIGN_OFFER_URL = (id) => `${BASE_URL}/freelancers/${id}/assign_offer`
const CANCEL_INVITE_URL = (id) => `${BASE_URL}/offers/${id}/cancel_invitation`
const RECOMMEND_FREELANCER_URL = (id) => `${BASE_URL}/offers/${id}/recommend`
const SEND_TO_JOB_BOARD_URL = (id) => `${BASE_URL}/offers/${id}/make_public`
const CHANGE_STATUS_URL = (id) => `${BASE_URL}/offers/${id}/change_status`
const MAKE_DONE_URL = (id) => `${BASE_URL}/offers/${id}/make_done`
const OFFER_STATUSES_URL = BASE_URL + '/statuses'
const STATUS_CHANGER_RULES_URL = BASE_URL + '/status_changer_rules'
const ADD_OFFER_COMMENT_URL = BASE_URL + '/comments'
const COMMENT_ATTACHMENT_URL = (id) => `${BASE_URL}/comments/${id}/add_attachment`

const state = {
  isProcessing: false,
  fetchError: null,
  allOpenOffers: [],
  openOfferDetails: {},
  offerTypes: [],
  calendarOffers: [],
  dateFromCalendar: '',
  offersTotal: 0,
  myOffersPage: 1,
  statuses: [],
  statusChangerRules: [],
  selected_freelancer: null
}

const getters = {
  allOpenOffers (state) {
    return state.allOpenOffers
  },
  openOfferDetails (state) {
    return state.openOfferDetails
  },
  calendarOffers (state) {
    return state.calendarOffers
  },
  dateFromCalendar (state) {
    return state.dateFromCalendar
  },
  myOffersPage (state) {
    return state.myOffersPage
  },
  offersTotal (state) {
    return state.offersTotal
  },
  offerTypes (state) {
    return state.offerTypes
  },
  offerStatuses (state) {
    return state.statuses
  },
  statusChangerRules (state) {
    return state.statusChangerRules
  },
  selected_freelancer (state) {
    return state.selected_freelancer
  }
}

const actions = {
  fetchAllOpenOffers ({commit, state}, filters = {}, pageNum = 1) {
    commit(types.ALL_OPEN_OFFERS_FETCH_PROCESSING)
    axios.get(`${OFFERS_URL}?page=${pageNum}&${qs.stringify(filters)}`).then((res) => {
      commit(types.ALL_OPEN_OFFERS_FETCH_SUCCESS, res.data)
    }).catch(error => {
      commit(types.ALL_OPEN_OFFERS_FETCH_ERROR, error)
    })
  },

  fetchOpenOfferDetails ({commit, state}, id) {
    commit(types.OPEN_OFFER_DETAILS_FETCH_PROCESSING)
    axios.get(OFFER_DETAILS_URL(id)).then((res) => {
      commit(types.OPEN_OFFER_DETAILS_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.OPEN_OFFER_DETAILS_FETCH_ERROR, error)
    })
  },

  fetchSingleOffer ({commit, state}, data) {
    commit(types.OPEN_OFFER_DETAILS_FETCH_PROCESSING)
    axios.get(OFFER_DETAILS_URL(data.offer_id)).then((res) => {
      const offer = res.data.data
      if (data.profile.role.id > 1 && (offer.freelancer && offer.freelancer.id !== data.profile.id)) {
        router.push('/jobBoard/' + data.offer_id)
      }
      commit(types.OPEN_OFFER_DETAILS_FETCH_SUCCESS, offer)
    }).catch(error => {
      commit(types.OPEN_OFFER_DETAILS_FETCH_ERROR, error)
      router.push('/')
    })
  },

  openOfferDetailsCancel ({commit, state}) {
    commit(types.OPEN_OFFER_DETAILS_CANCEL)
  },

  fetchOfferTypes ({commit, state}) {
    commit(types.OFFER_TYPES_FETCH_PROCESSING)
    axios.get(OFFER_TYPES_URL).then((res) => {
      commit(types.OFFER_TYPES_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.OFFER_TYPES_FETCH_ERROR, error)
    })
  },

  createJobOffer ({commit, state, dispatch}, data) {
    commit(types.CREATE_OFFER_PROCESSING)

    axios.post(OFFERS_URL, data.offerInfo).then((res) => {
      return res.data.data
    }).then(offer => {
      if (data.offerAttachments) {
        let attachment = new FormData()
        data.offerAttachments.forEach(file => {
          attachment.append('files[]', file)
        })
        axios.post(OFFER_DETAILS_URL(offer.id) + '/attach_file', attachment).then((res) => {
          commit(types.CREATE_OFFER_SUCCESS, res.data.data)
          router.push('/myCalendar/myJobOffers/' + offer.id)
        }).catch(error => {
          console.log(error)
        })
      } else {
        commit(types.CREATE_OFFER_SUCCESS, offer)
        router.push('/myCalendar/myJobOffers/' + offer.id)
        window.Materialize.toast('Offer created successfully', 4000)
      }
      if (data.sendToFreelancer) {
        dispatch('sendToFreelancer', {offer: offer.id, freelancer: data.sendToFreelancer.id})
      }
    }).catch(error => {
      commit(types.CREATE_OFFER_ERROR, error)
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 2000)
      } else {
        window.Materialize.toast('There was an issue creating an offer. Please try again later', 4000)
      }
    })
  },

  updateJobOffer: function ({commit, state, dispatch}, data) {
    commit(types.UPDATE_OFFER_PROCESSING)

    axios.put(OFFER_DETAILS_URL(data.offer_id), data.offerInfo).then((res) => {
      return res.data.data
    }).then(async offer => {
      if (data.attachmentsIdsToDelete && data.attachmentsIdsToDelete.length) {
        await axios.post(OFFER_DETAILS_URL(offer.id) + '/detach_files', {
          file_ids: data.attachmentsIdsToDelete
        })
      }

      if (data.offerAttachments && data.offerAttachments.length) {
        let attachment = new FormData()
        data.offerAttachments.forEach(file => attachment.append('files[]', file))
        await axios.post(OFFER_DETAILS_URL(offer.id) + '/attach_file', attachment)
      }

      if (data.sendToFreelancer) {
        dispatch('sendToFreelancer', {offer: offer.id, freelancer: data.sendToFreelancer.id})
      }

      commit(types.UPDATE_OFFER_SUCCESS, offer)
      router.push('/myCalendar/myJobOffers/' + offer.id)
      window.Materialize.toast('Offer updated successfully', 4000)
    }).catch(error => {
      commit(types.UPDATE_OFFER_ERROR, error)
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 2000)
      } else {
        window.Materialize.toast('There was an issue updating an offer. Please try again later', 4000)
      }
    })
  },

  fetchCalendarOffers ({commit, state, dispatch}, profile) {
    return new Promise((resolve, reject) => {
      if (profile.role.id === 1) {
        dispatch('fetchCompanyCalendarOffers', profile).then((res) => resolve(res))
      } else {
        dispatch('fetchFreelancerCalendarOffers', profile).then((res) => resolve(res))
      }
    })
  },

  fetchCompanyCalendarOffers ({commit, state}, profile) {
    return new Promise((resolve, reject) => {
      commit(types.OFFERS_FOR_COMPANY_FETCH_PROCESSING)
      axios.get(OFFERS_FOR_COMPANY_URL(profile.id) + '?per_page=500').then(res => {
        commit(types.OFFERS_FOR_COMPANY_FETCH_SUCCESS, res.data)
        resolve(state.calendarOffers)
      }).catch(error => {
        commit(types.OFFERS_FOR_COMPANY_FETCH_ERROR, error)
        reject(error)
      })
    })
  },

  fetchFreelancerCalendarOffers ({commit, state}, profile) {
    return new Promise((resolve, reject) => {
      commit(types.OFFERS_FOR_FREELANCER_FETCH_PROCESSING)
      axios.get(POSSIBLE_ASSIGNED_OFFERS_FOR_FREELANCER_URL(profile.id), { params: {per_page: 500} })
          .then(res => {
            commit(types.OFFERS_FOR_FREELANCER_FETCH_SUCCESS, res.data)
            resolve(state.calendarOffers)
          }).catch(error => {
            commit(types.OFFERS_FOR_FREELANCER_FETCH_ERROR, error)
            reject(error)
          })
    })
  },

  setDateFromCalendar ({commit, state}, date) {
    commit(types.SET_OFFER_DATE_FROM_CALENDAR, date)
  },

  sendToFreelancer ({commit, state}, data) {
    commit(types.SEND_TO_FREELANCER_PROCESSING)
    axios.put(SEND_TO_FREELANCER_URL(data.offer), {freelancer_id: data.freelancer}).then(res => {
      commit(types.SEND_TO_FREELANCER_SUCCESS, res.data.data)
      //router.push('/myCalendar/myJobOffers/' + data.offer)
      if (data.successMessage) {
        window.Materialize.toast(data.successMessage, 4000)
      }
    }).catch(error => {
      commit(types.SEND_TO_FREELANCER_ERROR, error)
    })
  },

  placeOfferBid ({commit, state}, data) {
    commit(types.PLACE_OFFER_BID_PROCESSING)
    axios.put(PLACE_OFFER_BID_URL(data.offer_id), {freelancer_id: data.freelancer_id, price: data.price, comment: data.comment}).then(res => {
      commit(types.PLACE_OFFER_BID_SUCCESS, res.data.data)
      window.Materialize.toast('Your bid has been submitted!', 4000)
    }).catch(error => {
      commit(types.PLACE_OFFER_BID_ERROR, error)
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 2000)
      } else {
        window.Materialize.toast('There was an error placing the bid. Please try again', 4000)
      }
    })
  },

  cancelOfferBid ({commit, state}, data) {
    commit(types.PLACE_OFFER_BID_PROCESSING)
    axios.put(CANCEL_OFFER_BID_URL(data.offer_id), {freelancer_id: data.freelancer_id}).then(res => {
      commit(types.PLACE_OFFER_BID_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PLACE_OFFER_BID_ERROR, error)
    })
  },

  acceptOfferBid ({commit, state}, data) {
    commit(types.ACCEPT_OFFER_BID_PROCESSING)
    axios.get(ACCEPT_OFFER_BID_URL(data.offer_id, data.bid_id)).then(res => {
      commit(types.ACCEPT_OFFER_BID_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.ACCEPT_OFFER_BID_ERROR, error)
    })
  },

  assignOffer ({commit, state}, data) {
    commit(types.ASSIGN_OFFER_PROCESSING)
    axios.post(ASSIGN_OFFER_URL(data.freelancer_id), {offer_id: data.offer_id}).then(res => {
      commit(types.ASSIGN_OFFER_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.ASSIGN_OFFER_ERROR, error)
    })
  },

  cancelInvite ({commit, state}, offer) {
    commit(types.CANCEL_INVITE_PROCESSING)
    axios.put(CANCEL_INVITE_URL(offer)).then(res => {
      commit(types.CANCEL_INVITE_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.CANCEL_INVITE_ERROR, error)
    })
  },

  recommendFreelancer ({commit, state}, data) {
    commit(types.RECOMMEND_FREELANCER_PROCESSING)
    axios.put(RECOMMEND_FREELANCER_URL(data.offer), {freelancer_id: data.freelancer}).then(res => {
      if (!data.hasOwnProperty('stayHere')) {
        router.push('/myCalendar')
      }
      commit(types.RECOMMEND_FREELANCER_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.RECOMMEND_FREELANCER_ERROR, error)
    })
  },

  sendToJobBoard ({commit, state}, offer) {
    commit(types.SEND_TO_JOB_BOARD_PROCESSING)
    axios.put(SEND_TO_JOB_BOARD_URL(offer.id)).then(res => {
      router.push('/myCalendar')
      commit(types.SEND_TO_JOB_BOARD_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.SEND_TO_JOB_BOARD_ERROR, error)
    })
  },

  cancelOffer ({commit, state}, {offer, comment = ''}) {
    commit(types.CANCEL_OFFER_PROCESSING)
    axios.put(CHANGE_STATUS_URL(offer.id), {status_id: OFFER_STATUSES.CANCELLED, comment}).then(res => {
      router.push('/myCalendar')
      commit(types.CANCEL_OFFER_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.CANCEL_OFFER_ERROR, error)
    })
  },

  doneOffer ({commit, state}, offer) {
    commit(types.DONE_OFFER_PROCESSING)
    axios.put(MAKE_DONE_URL(offer.id)).then(res => {
      commit(types.DONE_OFFER_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.DONE_OFFER_ERROR, error)
    })
  },

  fetchStatuses ({commit, state}) {
    commit(types.STATUSES_FETCH_PROCESSING)
    axios.get(OFFER_STATUSES_URL).then(res => {
      commit(types.STATUSES_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.STATUSES_FETCH_ERROR, error)
    })
  },

  fetchStatusChangerRules ({commit, state}) {
    commit(types.STATUS_CHANGER_RULES_FETCH_PROCESSING)
    axios.get(STATUS_CHANGER_RULES_URL).then(res => {
      commit(types.STATUS_CHANGER_RULES_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.STATUS_CHANGER_RULES_FETCH_ERROR, error)
    })
  },

  changeStatus ({commit, state}, data) {
    commit(types.SEND_TO_JOB_BOARD_PROCESSING)
    axios.put(CHANGE_STATUS_URL(data.offer), {status_id: data.status}).then(res => {
      commit(types.SEND_TO_JOB_BOARD_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.SEND_TO_JOB_BOARD_ERROR, error)
    })
  },

  addOfferComment ({commit, state, dispatch}, data) {
    commit(types.ADD_OFFER_COMMENT_PROCESSING)
    let formData = new FormData()

    formData.append('body', data.body)
    formData.append('offer_id', data.offer_id)
    formData.append('role_id', data.role_id)
    formData.append('user_id', data.user_id)

    if (data.attachments) {
      data.attachments.forEach(file => {
        formData.append('comment_attachments[]', file)
      })
    }

    axios.post(ADD_OFFER_COMMENT_URL, formData).then(res => {
      commit(types.ADD_OFFER_COMMENT_SUCCESS, res.data.data)
      dispatch('fetchOpenOfferDetails', data.offer_id)
      window.Materialize.toast('New comment added successfully', 2000)
    }).catch(error => {
      window.Materialize.toast(error.response.data.message, 5000)
      commit(types.ADD_OFFER_COMMENT_ERROR, error)
    })
  },

  offerFromFreelancersCalendar ({commit, state}, freelancer) {
    commit(types.SET_SELECTED_FREELANCER, freelancer)
  }
}

const mutations = {
  [types.ALL_OPEN_OFFERS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.ALL_OPEN_OFFERS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.allOpenOffers = data.data
    state.offersTotal = data.meta.total
    return state
  },
  [types.ALL_OPEN_OFFERS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.OPEN_OFFER_DETAILS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.OPEN_OFFER_DETAILS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.OPEN_OFFER_DETAILS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.OPEN_OFFER_DETAILS_CANCEL] (state) {
    return state.openOfferDetails = {}
  },
  [types.OFFER_TYPES_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.OFFER_TYPES_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.offerTypes = data
    return state
  },
  [types.OFFER_TYPES_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.CREATE_OFFER_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.CREATE_OFFER_SUCCESS] (state, data) {
    state.isProcessing = false
    return state
  },
  [types.CREATE_OFFER_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.OFFERS_FOR_COMPANY_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.OFFERS_FOR_COMPANY_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.calendarOffers = data.data
    state.offersTotal = data.meta.total
    return state
  },
  [types.OFFERS_FOR_COMPANY_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.OFFERS_FOR_FREELANCER_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.OFFERS_FOR_FREELANCER_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.calendarOffers = data.data
    state.offersTotal = data.meta.total
    return state
  },
  [types.OFFERS_FOR_FREELANCER_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.SET_OFFER_DATE_FROM_CALENDAR] (state, date) {
    return state.dateFromCalendar = date
  },
  [types.SEND_TO_FREELANCER_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.SEND_TO_FREELANCER_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.SEND_TO_FREELANCER_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.PLACE_OFFER_BID_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PLACE_OFFER_BID_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.PLACE_OFFER_BID_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.ACCEPT_OFFER_BID_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.ACCEPT_OFFER_BID_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.ACCEPT_OFFER_BID_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.CANCEL_OFFER_BID_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.CANCEL_OFFER_BID_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.CANCEL_OFFER_BID_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.ASSIGN_OFFER_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.ASSIGN_OFFER_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.ASSIGN_OFFER_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.CANCEL_INVITE_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.CANCEL_INVITE_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.CANCEL_INVITE_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.RECOMMEND_FREELANCER_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.RECOMMEND_FREELANCER_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.RECOMMEND_FREELANCER_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.SEND_TO_JOB_BOARD_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.SEND_TO_JOB_BOARD_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.SEND_TO_JOB_BOARD_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.CANCEL_OFFER_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.CANCEL_OFFER_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.CANCEL_OFFER_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.DONE_OFFER_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.DONE_OFFER_SUCCESS] (state, data) {
    state.isProcessing = false
    state.openOfferDetails = data
    return state
  },
  [types.DONE_OFFER_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.STATUSES_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.STATUSES_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.statuses = data
    return state
  },
  [types.STATUSES_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.STATUS_CHANGER_RULES_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.STATUS_CHANGER_RULES_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.statusChangerRules = data
    return state
  },
  [types.STATUS_CHANGER_RULES_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.ADD_OFFER_COMMENT_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.ADD_OFFER_COMMENT_SUCCESS] (state, data) {
    state.isProcessing = false
    return state
  },
  [types.ADD_OFFER_COMMENT_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  },
  [types.SET_SELECTED_FREELANCER] (state, freelancer) {
    state.selected_freelancer = freelancer
    return state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
