<template src="./SignInForm.html"></template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import Button from '../../components/Button.vue'
  import Preloader from '../../components/layout/Preloader.vue'
  import InputPassword from "@/components/form/InputPassword.vue";
  export default {
    data () {
      return {
        user: {
          email: '',
          password: ''
        }
      }
    },
    components: {
      InputPassword,
      'app-button': Button,
      'preloader': Preloader
    },
    computed: mapGetters(['authError']),
    methods: {
      ...mapActions(['signIn', 'cancelErrors']),

      submit (e) {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            return
          }
          this.signIn(this.user)
        })
      },
      errorClass (field) {
        return {
          error: this.authError[field] || this.errors.has(field)
        }
      }
    },
    beforeDestroy () {
      // this.errors = []
      this.cancelErrors()
    }
  }
</script>

<style lang="scss">
  @import "@/assets/styles/var_and_mixins";

  .sign-box {
    font-size: 18px;
    line-height: 32px;
    color: #565656;

    &__head {
      @include fo;
      font-size: 40px;
      line-height: 46px;
      text-transform: uppercase;
      color: $t;
      margin-bottom: 30px;
    }

    &-buttons {
      margin-top: 110px;
      display: flex;
      gap: 15px;
    }
  }

  @media screen and (max-width: 992px) {
    .sign-box {
      &-buttons {
        margin-top: 30px;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .sign-box {
      &-buttons {
        margin-top: 30px;
        flex-direction: column;
        align-items: center;
      }
    }
  }
</style>
