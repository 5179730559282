<template>
  <div class="syncCalendar">
    You can integrate &nbsp;
    <app-button :title="profileInfo.google_calendar_synced ? 'Google Calendar Integrated' : 'Google Calendar'"
                :disabled="profileInfo.google_calendar_synced"
                :onClick="googleAuth"/>
<!--    <app-button title="Outlook calendar" :onClick="outlookAuth"/>-->
  </div>
</template>

<script>
  import Button from '../../components/Button.vue'
  import {mapActions, mapGetters} from 'vuex'
  import * as types from '../../vuex/profile/mutationTypes'

  export default {
    components: {
      'app-button': Button
    },
    methods: {
      ...mapActions(['fetchGoogleUrlAuth', 'fetchOutlookUrlAuth', 'fetchGoogleData', 'exportDataToGoogle']),
      googleAuth () {
        this.fetchGoogleUrlAuth().then(res => window.open(res, '_self'))
      },
      outlookAuth () {
        this.fetchOutlookUrlAuth().then(res => window.open(res))
      }
    },
    computed: {
      ...mapGetters({
        profileInfo: 'profileInfo'
      })
    },
    mounted () {
      if (this.$route.query.code) {
        this.exportDataToGoogle(this.$route.query.code).then(res => {
          this.$store.commit(types.PROFILE_CALENDAR_SYNCED_SUCCESS)
          this.$router.push({name: 'myCalendar'})
        })
      }
    }
  }
</script>

<style>
  .syncCalendar {
    width: 100%;
    text-align: right;
    padding: 30px 30px;
  }
</style>
