const OFFER_STATUSES = {
  DRAFT_OFFER: 1,
  OFFER_PUBLISHED: 2,
  BOOKING_ACCEPTED: 3,
  OFFER_IS_PAYED: 4,
  OFFER_IN_PROGRESS: 5,
  OFFER_IN_REVIEW: 6,
  PENDING_ALERT: 7,
  SUPPORT_REVIEW: 8,
  CANCELLED: 17,
  DECLINED: 18,
  DONE: 19
}

const NOTIFICATION_CATEGORY = {
  PAYMENT: 1,
  JOB_DETAILS: 2,
}

const NOTIFICATION_SUBCATEGORY = {
  SUBCATEGORY_PAYMENT_WAIT: 100,
  SUBCATEGORY_PAYMENT_RECEIVED: 101,

  SUBCATEGORY_JOB_DETAILS_BID: 200,
  SUBCATEGORY_JOB_DETAILS_INVITE: 201,

  SUBCATEGORY_JOB_DETAILS_CHANGE: 230,
  SUBCATEGORY_JOB_DETAILS_COMMENT: 240
}

export {OFFER_STATUSES, NOTIFICATION_CATEGORY, NOTIFICATION_SUBCATEGORY}
