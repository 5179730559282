import axios from 'axios'
import ConfigHelper from '../../helpers/ConfigHelper'
import * as types from './mutationTypes'

const BASE_URL = ConfigHelper.get('apiUrl')
const STATES_URL = BASE_URL + '/states'
const LOCATIONS_URL = BASE_URL + '/locations'

const state = {
  isProcessing: false,
  fetchError: null,
  states: [],
  locations: []
}

const getters = {
  states (state) {
    return state.states
  },

  locations (state) {
    return state.locations
  }
}

const actions = {
  fetchStates ({commit, state}) {
    if (!state.states.length) {
      commit(types.STATES_FETCH_PROCESSING)
      axios.get(STATES_URL).then((res) => {
        commit(types.STATES_FETCH_SUCCESS, res.data.data)
      }).catch(error => {
        commit(types.STATES_FETCH_ERROR, error)
      })
    }
  },
  fetchLocations ({commit, state}) {
    commit(types.LOCATIONS_FETCH_PROCESSING)
    axios.get(LOCATIONS_URL).then((res) => {
      commit(types.LOCATIONS_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.LOCATIONS_FETCH_ERROR, error)
    })
  }
}

const mutations = {
  [types.STATES_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.STATES_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.states = data
    return state
  },
  [types.STATES_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.LOCATIONS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.LOCATIONS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.locations = data
    return state
  },
  [types.LOCATIONS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
