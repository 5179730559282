<template>
  <div>
    <input v-model="dateValue" placeholder="MM/DD/YYYY" class="datepicker" @click="openCalendar" @input="updateDate">
    <div class="datepicker-date">
      <div class="datepicker-calendar"></div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        calendar: null,
        dateValue: this.value || ''
      }
    },
    props: {
      value: {
        type: String,
        default: null
      },
      dateFormat: {
        type: String,
        default: 'MM/DD/YYYY'
      },
      birthDate: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        required: true
      }
    },
    methods: {
      openCalendar () {
        this.calendar.addClass('visible');
      },
      updateDate () {
        this.$emit('input', this.dateValue);
        this.$emit('change', this.dateValue);
      }
    },
    watch: {
      value(newValue) {
        this.dateValue = newValue || ''
      }
    },
    mounted () {
      this.calendar = window.$('.datepicker-calendar').fullCalendar({
        header: {
          left: 'prevYear, prev',
          center: 'title',
          right: 'next, nextYear'
        },
        dayClick: (date, jsEvent, view) => {
          this.dateValue = date.format(this.dateFormat)
          this.updateDate()
          window.$(this.$el).find('.datepicker-calendar').removeClass('visible')
        }
      })

      const dateToGo = this.birthDate ? '1980-01-01' : Date.now()

      this.calendar.fullCalendar('gotoDate', this.dateValue || dateToGo)
    }
  }
</script>

<style lang="scss">
  .datepicker-calendar {
    overflow: auto;
    width: 300px;
    .fc-day-grid-container {
      height: 240px !important;
    }
  }
</style>
