<template>
  <div>
    <landing/>
    <app-footer v-if="!!isAuthenticated"/>
  </div>
</template>
<script>
import Footer from '../../components/layout/Footer.vue'
import Landing from './Landing'

export default {
  components: {
    'app-footer': Footer,
    'landing': Landing
  },
  props: ['isAuthenticated'],
}

</script>

<style>
.footer {
  display: block;
  z-index: 1;
  position: relative;
  background: #ffffff;
}
</style>
