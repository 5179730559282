import axios from 'axios'
import qs from 'qs'

import ConfigHelper from '../../helpers/ConfigHelper'
import * as types from './mutationTypes'

const BASE_URL = ConfigHelper.get('apiUrl')
const FREELANCER_LIST_URL = BASE_URL + '/freelancers'
const FREELANCER_DETAILS_URL = (id) => `${FREELANCER_LIST_URL}/${id}`
const ASSIGNED_OFFERS_FOR_FREELANCER_URL = (id) => `${BASE_URL}/freelancers/${id}/secret_offers`
const FREELANCER_REWIEWS = (id) => BASE_URL + `/freelancer/${id}/recalls`
const FREELANCER_ADD_RATING_URL = BASE_URL + '/freelancer/rating/add'
const FREELANCER_ADD_COMPLAIN_URL = BASE_URL + '/freelancers/complain'

const freelancerListDefaultFilters = {
  categories: [],
  type_id: [],
  role_id: null,
  location: '',
  location_name: ''
}

const state = {
  isProcessing: false,
  fetchError: null,
  freelancerList: [],
  freelancerListDefaultFilters: {...freelancerListDefaultFilters},
  freelancerListFilters: {...freelancerListDefaultFilters},
  freelancerTotal: 0,
  freelancerListPage: 1,
  freelancerDetails: {},
  freelancerOffers: [],
  freelancerReviews: []
}

const getters = {
  freelancerList (state) {
    return state.freelancerList
  },
  freelancerListDefaultFilters (state) {
    return state.freelancerListDefaultFilters
  },
  freelancerListFilters (state) {
    return state.freelancerListFilters
  },
  freelancerListPage (state) {
    return state.freelancerListPage
  },
  freelancerTotal (state) {
    return state.freelancerTotal
  },
  freelancerDetails (state) {
    return state.freelancerDetails
  },
  freelancerOffers (state) {
    return state.freelancerOffers
  },
  freelancerReviews (state) {
    return state.freelancerReviews
  }
}

const actions = {
  setFreelancerListFilters ({commit, state}, filters = {}) {
    commit(types.FREELANCER_LIST_SET_FILTERS, filters)
  },

  fetchFreelancerList ({commit, state}, filters = {}) {
    commit(types.FREELANCER_LIST_FETCH_PROCESSING)
    axios.get(`${FREELANCER_LIST_URL}?page=${filters.pageNum || 1}&${qs.stringify(filters)}`).then((res) => {
      commit(types.FREELANCER_LIST_FETCH_SUCCESS, {
        freelancerList: res.data.data,
        freelancerListPage: filters.pageNum || 1,
        freelancerTotal: res.data.meta.total
      })
    }).catch(error => {
      commit(types.FREELANCER_LIST_FETCH_ERROR, error)
    })
  },

  fetchFreelancerDetails ({commit, state}, id) {
    commit(types.FREELANCER_DETAILS_FETCH_PROCESSING)
    axios.get(FREELANCER_DETAILS_URL(id)).then((res) => {
      commit(types.FREELANCER_DETAILS_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.FREELANCER_DETAILS_FETCH_ERROR, error)
    })
  },

  freelancerDetailsCancel ({commit, state}) {
    commit(types.FREELANCER_DETAILS_CANCEL)
  },

  fetchFreelancerOffers ({commit, state}, profileId) {
    commit(types.FREELANCER_OFFERS_FETCH_PROCESSING)
    axios.get(ASSIGNED_OFFERS_FOR_FREELANCER_URL(profileId)).then(res => {
      commit(types.FREELANCER_OFFERS_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.FREELANCER_OFFERS_FETCH_ERROR, error)
    })
  },

  addFreelancerRating ({commit, state}, data) {
    commit(types.FREELANCER_ADD_RATING_PROCESSING)
    axios.post(FREELANCER_ADD_RATING_URL, data).then((res) => {
      commit(types.FREELANCER_ADD_RATING_SUCCESS, res.data.data)
      if (res.data && res.data.message.length) {
        window.Materialize.toast(res.data.message, 2000)
      } else {
        window.Materialize.toast('Your feedback added', 2000)
      }
    }).catch(error => {
      commit(types.FREELANCER_ADD_RATING_ERROR, error)
    })
  },

  addFreelancerComplain ({commit, state}, data) {
    axios.post(FREELANCER_ADD_COMPLAIN_URL, data).then((res) => {
      if (res.data && res.data.message.length) {
        window.Materialize.toast(res.data.message, 2000)
      } else {
        window.Materialize.toast('Your complain sended', 2000)
      }
    }).catch(error => {
      window.Materialize.toast(error, 2000)
    })
  },

  fetchFreelancerReviews ({commit, state}, id) {
    commit(types.FREELANCER_REWIEWS_FETCH_PROCESSING)
    axios.get(FREELANCER_REWIEWS(id)).then((res) => {
      commit(types.FREELANCER_REWIEWS_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.FREELANCER_REWIEWS_FETCH_ERROR, error)
    })
  }
}

const mutations = {
  [types.FREELANCER_LIST_SET_FILTERS] (state, data) {
    state.freelancerListFilters = data
    return state
  },
  [types.FREELANCER_REWIEWS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.FREELANCER_REWIEWS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.freelancerReviews = data
    return state
  },
  [types.FREELANCER_REWIEWS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.FREELANCER_LIST_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.FREELANCER_LIST_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.freelancerList = data.freelancerList
    state.freelancerTotal = data.freelancerTotal
    state.freelancerListPage = data.freelancerListPage
    return state
  },
  [types.FREELANCER_LIST_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.FREELANCER_DETAILS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.FREELANCER_DETAILS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.freelancerDetails = data
    return state
  },
  [types.FREELANCER_DETAILS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.FREELANCER_DETAILS_CANCEL] (state) {
    return state.freelancerDetails = {}
  },
  [types.FREELANCER_OFFERS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.FREELANCER_OFFERS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.freelancerOffers = data
    return state
  },
  [types.FREELANCER_OFFERS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.FREELANCER_ADD_RATING_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.FREELANCER_ADD_RATING_SUCCESS] (state) {
    state.isProcessing = false
    return state
  },
  [types.FREELANCER_ADD_RATING_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
