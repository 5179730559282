<template>
  <div class="wrapper-inner">
    <div class="list-container">
      <div class="list-container__head clearfix">
        <div class="list-container__counter">Companies<span class="count">{{companyTotal}}</span></div>
        <div class="list-container__sort">
          <!--<app-select name="filters">-->
            <!--<option value="0" selected="">Sort by rating</option>-->
            <!--<option value="1">Sort by active offers</option>-->
            <!--<option value="2">Sort by name</option>-->
          <!--</app-select>-->
        </div>
      </div>
      <div class="list-container__body">
        <router-link tag="div" :to="'companies/' + company.id" class="list-item" v-for="company in companyList" :key="company.id">
          <div class="list-item-row">
            <div v-if="company.image_url"
                 class="avatar-image"
                 id="profileImage"
                 :style="{ backgroundImage: 'url(' + getStorageUrl() + company.image_url + ')' }">
            </div>
            <div v-else class="avatar-image"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
            <span class="complain" @click.prevent="openComplainModal(company.id, company.name)">Complain</span>
            <div class="list-item-col list-item-col--2">
              <div class="list-item__name">
                <span>{{company.name}}</span>
                <template v-if="company.location">{{company.location.formatted_address}}</template>
              </div>
              <div class="list-item__list">
                <ul>
                  <li>
                    <div class="active-offers">
                      <span class="icon-ico-58-active-offers"></span>Active offers<span class="active-offers-count">{{company.activeOffers}}</span>
                    </div>
                  </li>
                  <li> <span class="icon-ico-59-job"></span><span class="list-item-count">{{company.allOffers}} </span>job offers for all time</li>
                  <li> <span class="icon-ico-60-complete"></span><span class="list-item-count">{{company.doneOffers}} </span>job offers successfully completed</li>
                </ul>
              </div>
            </div>
            <div class="list-item-col list-item-col--3">
              <div class="list-item-raiting" v-if="company.rating"><span class="icon-ico-53-rate"></span>{{company.rating}}</div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="list-container__bottom">
        <pagination :current-page="companyListPage || 1"
                    :total-items="companyTotal"
                    :itemsPerPage="15"
                    @page-changed="pageChanged">
        </pagination>
      </div>
    </div>
    <div id="modal-add-complain" class="modal">
      <div class="modal-head">Report an issue</div>
      <div class="modal-content">
        <form @submit.prevent="sendComplain">
          <div class="input-field required-field" data-error="Required field">
            <textarea class="materialize-textarea" required v-model="feedbackComm" placeholder="Comment" aria-invalid="false" rows="15"></textarea>
          </div>
          <div class="modal-content__button-wrapper">
            <app-button title="Send" :onClick.prevent="sendComplain" />
            <app-button title="Cancel" cssClass="modal-close"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import Select from '../../components/Select'
  import Pagination from '../../components/Pagination.vue'
  import ImageHelper from '../../helpers/ImageHelper'
  import Button from '../../components/Button.vue'
  export default {
    data () {
      return {
        complain_id: '',
        complain_name: '',
        feedbackComm: ''
      }
    },
    computed: {
      ...mapGetters(['companyList', 'companyTotal', 'companyListPage', 'profileInfo'])
      //      sortedCompanyList () {
      //        return this.companyList.sort(function (a, b) {
      //          if (b.rating < a.rating) return -1
      //          if (b.rating > a.rating) return 1
      //        })
      //      }
    },
    mixins: [ImageHelper],
    methods: {
      ...mapActions(['fetchCompanyList', 'addCompanyComplain']),
      openComplainModal (id, name) {
        this.complain_id = id
        this.complain_name = name
        window.$('#modal-add-complain').modal('open')
      },
      sendComplain () {
        if (this.feedbackComm.length === 0) {
          window.$('#modal-add-complain').find('.required-field').addClass('error')
          return false
        } else {
          window.$('#modal-add-complain').find('.required-field').removeClass('error')
        }
        this.addCompanyComplain({freelancer_id: this.profileInfo.id, message: this.feedbackComm, company_id: this.complain_id})
        window.$('.modal').modal('close')
        this.feedbackComm = ''
      },
      pageChanged (pageNum) {
        this.fetchCompanyList(pageNum)
      }
    },
    components: {
      'app-button': Button,
      'app-select': Select,
      'pagination': Pagination
    },
    mounted () {
      window.$('.modal').modal({
        ready () {
          window.$('.modal-overlay').detach().appendTo('.wrapper')
        }
      })
      this.fetchCompanyList(this.companyListPage)
    }
  }
</script>
<style>
  #modal-add-complain textarea {
    min-height: 100px !important;
  }
  .complain {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #d9ba4c;
    text-align: center;
    cursor: pointer;
  }
  .complain:hover {
    color:red;
  }
</style>
