<template>
  <div class="calendar-body">
    <div class="list-container">
      <div class="list-container__body">
        <div class="list-container-body-head align-center">Job Offers In Work</div>
        <div v-if="offersInWork.length === 0">No job offers in work at the moment</div>
        <div v-else v-for="offer in offersInWork" class="offer-item">
          <div class="offer-item__name">{{offer.name}}</div>
          <div class="offer-item__content">
            <div class="offer-item__detail">
              <ul>
                <li>
                  <span class="icon-ico-40-date"></span>
                  {{formatDate(offer.date)}} - {{formatTime(offer.time)}}
                </li>
                <li><span class="icon-ico-42-money"></span>$ {{offer.price}}</li>
                <li><div class="offers-box-item__status">{{getStatusName(offer)}}</div></li>
              </ul>
            </div>
            <div class="offer-item__info">
              <p class="text-limiter">{{offer.description}}</p>
              <ul>
                <li><span>Type:</span>{{offer.offerType.name}}</li>
                <li><span class="icon-ico-43-category"></span>{{offer.category.name}}</li>
                <li><span class="icon-ico-44-location"></span>{{offer.location.formatted_address}}</li>
                <li v-if="offer.conference_link">
                  <span>Conference link</span>
                  <template v-for="(part, index) in parseConferenceLink(offer.conference_link)">
                    <a v-if="part.isUrl" :href="part.text" target="_blank" style="margin-right: 10px; color: #575757">{{ part.text }}</a>
                    <span v-else style="color: #575757">{{ part.text }}</span>
                    <span v-if="index < parseConferenceLink(offer.conference_link).length - 1" style="color: #9d9d9d">|</span>
                  </template>
                </li>
              </ul>
              <div class="offer-item__button">
                <router-link tag="div" :to="'/myCalendar/myJobOffers/' + offer.id" class="offer-item__button">
                  <app-button title="view" cssClass="btn--default" :slideOnHover="false"/>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Button from '../../components/Button.vue'
  import StatusHelper from '../../helpers/StatusHelper'
  import DateHelper from '../../helpers/DateHelper'
  import LinkHelper from '@/helpers/LinkHelper'

  export default {
    mixins: [StatusHelper, DateHelper, LinkHelper],
    computed: {
      ...mapGetters(['calendarOffers']),
      offersInWork () {
        return this.calendarOffers.filter(offer => {
          return offer.status !== null && this.isActive(offer)
        })
      }
    },
    components: {
      'app-button': Button
    }
  }
</script>
