<template>
  <div class="general-body general-body--grey change-password-box profile-details clearfix">
      <div class="general-body-col">
        <div class="profile-details__head"><span class="icon-ico-29"></span>Change password</div>
        <div class="profile-details__body">
          <form action="#">
            <div :data-error="errors.first('Password.Old password')"
                 :class="[{error: this.errors.has('Password.Old password')}, 'input-field', 'required-field']">
              <div class="label-field">Old password</div>
              <div class="label-area">
                <input
                  type="password"
                  v-validate="'required|min: 1'"
                  v-model="oldPassword"
                  name="Old password"
                  data-vv-scope="Password"
                  data-vv-validate-on="submit">
              </div>
            </div>
            <div :data-error="errors.first('Password.Password')"
                 :class="[{error: this.errors.has('Password.Password')}, 'input-field', 'required-field']">
              <div class="label-field">Password</div>
              <div class="label-area">
                <input
                  type="password"
                  v-validate="'required'"
                  v-model="newPassword"
                  ref="Password"
                  name="Password"
                  data-vv-scope="Password"
                  data-vv-validate-on="submit">
              </div>
            </div>
            <div
              :data-error="errors.first('Password.Confirm password')"
              :class="[{error: this.errors.has('Password.Confirm password')}, 'input-field', 'required-field']">
              <div class="label-field">Confirm password</div>
              <div class="label-area">
                <input
                  type="password"
                  v-validate="'required|min: 6|confirmed:Password'"
                  name="Confirm password"
                  data-vv-as="Password"
                  data-vv-scope="Password"
                  data-vv-validate-on="submit">
              </div>
            </div>

            <div class="input-field">
              <div class="label-field"> &nbsp;</div>
              <div class="label-area">
                <app-button :onClick.prevent="updatePassword" title="Change"/>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="general-body-col">
        <div class="profile-details__body add-skills-container">
          <div class="profile-details__head"><span class="icon-ico-30"></span>Change E-mail</div>
          <div class="profile-details__body">
            <form action="#">
              <div :data-error="errors.first('Email.New Email')"
                   :class="[{error: this.errors.has('Email.New Email')}, 'input-field', 'required-field']">
                <div class="label-field">New Email</div>
                <div class="label-area">
                  <input
                    type="text"
                    v-validate="'required|email'"
                    v-model="newEmail"
                    ref="New Email"
                    name="New Email"
                    data-vv-scope="Email"
                    data-vv-validate-on="submit">
                </div>
              </div>
              <div
                :data-error="errors.first('Email.Confirm Email')"
                :class="[{error: this.errors.has('Email.Confirm Email')}, 'input-field', 'required-field']">
                <div class="label-field">Confirm Email</div>
                <div class="label-area">
                  <input
                    type="text"
                    v-validate="'required|email|confirmed:New Email'"
                    name="Confirm Email"
                    data-vv-as="New Email"
                    data-vv-scope="Email"
                    data-vv-validate-on="submit">
                </div>
              </div>
              <div class="input-field">
                <div class="label-field"> &nbsp;</div>
                <div class="label-area">
                  <app-button :onClick.prevent="updateEmail" title="Change"/>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import Button from '../../components/Button.vue'
  export default {
    data () {
      return {
        oldPassword: '',
        newPassword: '',
        newEmail: '',
        passwordErrors: []
      }
    },
    computed: {
      ...mapGetters(['profileInfo'])
    },
    methods: {
      ...mapActions(['changePassword', 'updateProfileInfo', 'logout']),
      updatePassword () {
        Promise.all([
          this.$validator.validateAll('Password'),
          this.$validator.validateAll('oldPassword')
        ]).then((results) => {
          if (!results[0] || !results[1]) return
          let passObj = {
            newPassword: this.newPassword,
            oldPassword: this.oldPassword
          }
          this.changePassword(passObj)
        })
      },
      updateEmail () {
        this.$validator.validateAll('Email').then((result) => {
          if (!result) return
          this.$swal({
            title: 'Are you sure?',
            text: 'You will be signed out and we will send you a confirmation email',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#ffffff',
            cancelButtonColor: '#ffffff',
            confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`,
            cancelButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light cancel">Cancel</button>`
          }).then(() => {
            this.updateProfileInfo({email: this.newEmail})
            this.logout()
          })
        })
      }
    },
    components: {
      'app-button': Button
    }
  }
</script>

<style lang="scss">
  .swal2-confirm, .swal2-cancel {
    &:hover {
      background: #ffffff !important;
    }
  }
</style>
