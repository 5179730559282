<template>
  <div v-if="shouldShow" class="app-store">
    <a :href="link">
      <img src="/images/app-store.svg" alt="Download on the App Store">
    </a>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    },
    shouldShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.app-store {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    margin: 40px;
    height: 60px;
  }
}
</style>
