<template>
  <div>
    <div class="wrapper-inner wrapper-inner--grey wrapper-inner--freelancer">
      <div class="row">
        <div class="col l3 s12 right">
          <div class="sidebar-filter filter-box">
            <div class="filter-box__head clearfix"><span>Categories</span>
              <a class="back right" @click="resetCategoryFilters" :class="{ 'inactive': !isCategoriesResetActive }">
                <div class="reset"><span class="icon-ico-38-close"></span>Reset</div></a>
            </div>
            <div class="filter-box__body">
              <form action="#">
                <div class="sidebar-box-group">
                  <div v-for="category in allCategories" class="check-box">
                    <input type="checkbox" :id="'category-' + category.id" class="filled-in" :value="category.id" v-model="filters.categories">
                    <label :for="'category-' + category.id">{{category.name}}</label>
                  </div>
                </div>
                <app-button title="Apply All" :onClick="checkAllCategories" cssClass="btn--white"/>
              </form>
            </div>
          </div>
        </div>
        <div class="col l9 s12">
          <div class="filter-box">
            <div class="filter-box__head clearfix">
              <span>Filter list</span>
              <a class="back right" @click="resetFilters" :class="{ 'inactive': !isResetActive }">
                <div class="reset"><span class="icon-ico-38-close"></span>Reset</div>
              </a>
            </div>
            <div class="filter-box__body">
              <form action="#">
                <div class="row">
                  <div class="col l9 s12">
                    <div data-error="Required field" class="input-field search-field"><span class="label">&nbsp;</span>
                      <input type="text" v-model="filters.search" placeholder="Search here...">
                    </div>
                  </div>
                  <!--<div class="col l3 s12">-->
                  <!--<div class="input-field required-field">-->
                  <!--<div class="label">Date</div>-->
                  <!--<datepicker v-model="filters.date" name="Date" v-validate="'required'" />-->
                  <!--</div>-->
                  <!--</div>-->
                  <div class="col l4 s12">
                    <div class="input-field"><span class="label">Location</span>
                      <location-input
                        :getLocationData="getLocationData"
                        v-model="filters.location_name"
                        :value="filters.location_name"
                        name="Location"
                      />
                    </div>
                  </div>
                  <div class="col l4 s12">
                    <div class="input-field"><span class="label">Type of freelancers</span>
                      <app-select name="freelancer-type" v-model.number="filters.role_id" :onChange="handleRoleChange">
                        <option value="" disabled="" selected="">Select type</option>
                        <option value="2">Lawyer</option>
                        <option value="3">NonLawyer</option>
                      </app-select>
                    </div>
                  </div>
                  <div class="col l8 s12">
                    <div :class="[{disabled: this.isTypeDisabled}, 'check-box-group']"><span class="label">Type of Nonlawyer</span>
                      <div class="check-box">
                        <input id="check1" type="checkbox" :disabled="isTypeDisabled" class="filled-in" value="2" v-model="filters.type_id">
                        <label for="check1">Paralegal</label>
                      </div>
                      <div class="check-box">
                        <input id="check2" type="checkbox" :disabled="isTypeDisabled" class="filled-in" value="3" v-model="filters.type_id">
                        <label for="check2">Stenographer</label>
                      </div>
                      <div class="check-box">
                        <input id="check3" type="checkbox" :disabled="isTypeDisabled" class="filled-in" value="4" v-model="filters.type_id">
                        <label for="check3">Expert witness</label>
                      </div>
                    </div>
                    <!--<div class="check-box-group"><span class="label">Sort</span>-->
                    <!--<div class="check-box">-->
                    <!--<input id="check4" type="radio" class="filled-in">-->
                    <!--<label for="check4">By rating</label>-->
                    <!--</div>-->
                    <!--</div>-->
                  </div>
                  <div class="col l4 s12 align-right">
                    <app-button title="Filter" :onClick="applyFilters" cssClass="btn--white"/>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="list-container list-container--border">
            <div class="list-container-body-head">Freelancers</div>
            <div class="list-container__body">
              <div v-for="freelancer in freelancerList" class="list-item list-item-freelancer">
                <router-link tag="div" :to="'freelancers/' + freelancer.id" class="list-item-row">
                  <div class="list-item-col list-item-col--1">
                  <span class="list-item-icon">
                    <div v-if="freelancer.image_url"
                         class="avatar-image"
                         id="profileImage"
                         :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"> </div>
                    <div v-else class="avatar-image"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
                  </span>
                    <span class="complain" @click.prevent="openComplainModal(freelancer.id, freelancer.name, freelancer.lastname)">Complain</span>
                  </div>
                  <div class="list-item-col list-item-col--2">
                    <div class="list-item__name">
                      <span>{{freelancer.name}} {{freelancer.lastname}}</span>{{freelancer.role.name}}
                      <div v-if="freelancer.rating" class="list-raiting"><span class="icon-ico-53-rate"></span>{{freelancer.rating}}</div>
                    </div>
                    <div class="list-item__list">
                      <ul>
                        <li v-for="(category, index) in freelancer.categories">
                          <span v-if="index === 0" class="icon-ico-43-category"></span>
                          {{category.name}}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="list-item-col list-item-col--3"><app-button cssClass="btn--default" title="Offer" :slideOnHover="false"/></div>
                </router-link>
              </div>
              <div class="list-container__bottom">
                <pagination :current-page="freelancerListPage"
                            :total-items="freelancerTotal"
                            :itemsPerPage="15"
                            @page-changed="pageChanged">
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal-add-complain" class="modal">
      <div class="modal-head">Report an issue</div>
      <div class="modal-content">
        <form @submit.prevent="sendComplain">
          <div class="input-field required-field" data-error="Required field">
            <textarea class="materialize-textarea" required v-model="feedbackComm" placeholder="Comment" aria-invalid="false" rows="15"></textarea>
          </div>
          <div class="modal-content__button-wrapper">
            <app-button title="Send" :onClick.prevent="sendComplain" />
            <app-button title="Cancel" cssClass="modal-close"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import Select from '../../components/Select.vue'
  import Button from '../../components/Button.vue'
  import Pagination from '../../components/Pagination.vue'
  import LocationInput from '../../components/LocationInput.vue'
  import Datepicker from '../../components/Datepicker.vue'
  import ImageHelper from '../../helpers/ImageHelper'
  export default {
    data () {
      return {
        filters: {
          categories: [],
          type_id: [],
          role_id: null,
          location: '',
          location_name: ''
        },
        isTypeDisabled: true,
        complain_id: '',
        complain_name: '',
        complain_last_name: '',
        feedbackComm: ''
      }
    },
    mixins: [ImageHelper],
    computed: {
      ...mapGetters(['profileInfo', 'freelancerList', 'states', 'locations', 'allCategories', 'freelancerListPage', 'freelancerListDefaultFilters', 'freelancerListFilters', 'freelancerTotal']),
      isCategoriesResetActive () {
        return this.filters.categories.length > 0
      },
      isResetActive () {
        const currentFilters = {...this.filters, categories: []}
        const defaultFilters = {...this.freelancerListDefaultFilters, categories: []}
        return JSON.stringify(currentFilters) !== JSON.stringify(defaultFilters)
      }
    },
    methods: {
      ...mapActions(['fetchFreelancerList', 'fetchStates', 'fetchLocations', 'fetchCategories', 'addFreelancerComplain', 'setFreelancerListFilters']),
      openComplainModal (id, name, lastName) {
        this.complain_id = id
        this.complain_name = name
        this.complain_last_name = lastName
        window.$('#modal-add-complain').modal('open')
      },
      sendComplain () {
        if (this.feedbackComm.length === 0) {
          window.$('#modal-add-complain').find('.required-field').addClass('error')
          return false
        } else {
          window.$('#modal-add-complain').find('.required-field').removeClass('error')
        }
        this.addFreelancerComplain({freelancer_id: this.complain_id, message: this.feedbackComm, company_id: this.profileInfo.id})
        window.$('.modal').modal('close')
        this.feedbackComm = ''
      },
      applyFilters () {
        this.setFreelancerListFilters(this.filters)
        this.fetchFreelancerList(this.filters)
      },
      resetCategoryFilters () {
        if (!this.isCategoriesResetActive) return
        this.filters.categories = []
        this.setFreelancerListFilters(this.filters)
        this.fetchFreelancerList(this.filters)
      },
      resetFilters () {
        if (!this.isResetActive) return
        this.filters = {
          ...JSON.parse(JSON.stringify(this.freelancerListDefaultFilters)),
          categories: this.filters.categories
        }
        this.setFreelancerListFilters(this.filters)
        this.fetchFreelancerList(this.filters)
      },
      checkAllCategories () {
        this.filters.categories = []
        this.allCategories.forEach(category => {
          this.filters.categories.push(category.id)
        })
      },
      pageChanged (pageNum) {
        this.fetchFreelancerList({...this.filters, pageNum})
      },
      getLocationData (res) {
        this.filters.location = res.formatted_address
        this.filters.location_name = res.formatted_address
      },
      handleRoleChange (value) {
        if (value !== 3) {
          this.isTypeDisabled = true
          this.filters.type_id = []
        } else {
          this.isTypeDisabled = false
        }
      }
    },
    components: {
      'app-button': Button,
      'app-select': Select,
      'pagination': Pagination,
      'location-input': LocationInput,
      'datepicker': Datepicker
    },
    mounted () {
      window.$('.modal').modal({
        ready () {
          window.$('.modal-overlay').detach().appendTo('.wrapper')
        }
      })
      this.filters = this.freelancerListFilters
      this.fetchFreelancerList({...this.freelancerListFilters, pageNum: this.freelancerListPage})
      this.fetchStates()
      this.fetchCategories()
    }
  }
</script>

<style lang="scss">
  #modal-add-complain textarea {
    min-height: 100px !important;
  }
  .complain {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #d9ba4c;
    text-align: center;
    cursor: pointer;
  }
  .complain:hover {
    color:red;
  }
  .reset {
    cursor: pointer;
  }

  .check-box-group.disabled {
    .label, label {
      color: #c3c3c3;
    }
  }
</style>
