<template>
  <div class="general-body general-body--grey clearfix">
    <div class="profile-details clearfix">
      <div class="profile-details__body">
        <div v-if="payments" class="payment-history-container">
          <div class="payment-history">
            <div class="payment-history__head">
              <div class="payment-col payment-col--1">Time and Date</div>
              <div class="payment-col payment-col--2">Amount</div>
              <div class="payment-col payment-col--3">From/To</div>
              <div class="payment-col payment-col--4">Description</div>
            </div>
            <!--TODO: use fetched data -->
            <div class="payment-history__body">
              <div :key="pay.id" class="payment-history__item" v-for="pay in payments">
                <div class="payment-col payment-col--1"> <span>{{pay.date}}</span></div>
                <div class="payment-col payment-col--2"> <span>{{priceFormatter(pay.amount)}}</span></div>
                <div class="payment-col payment-col--3"><span>{{pay.person}}</span></div>
                <div class="payment-col payment-col--4"><div class="payment-detail">{{pay.description}}</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  export default {
    computed: mapGetters(['payments', 'isFreelancer']),
    methods: {
      ...mapActions(['fetchPaymentHistory']),
      priceFormatter: function (amount) {
        amount = Number.isNaN(amount) || amount === null ? 0 : amount
        let isNegative = amount < 0 ? '-' : ''
        return isNegative + '$' + amount.toString().replace('-', '')
      }
    },
    mounted () {
      this.fetchPaymentHistory()
    }
  }
</script>
