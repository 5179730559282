<template>
  <div>
    <!--header-->
    <header class="header clearfix">
      <div class="container">
        <div class="logo"><router-link to="/"><img src="../../assets/images/logo.svg" alt=""></router-link></div>
        <div class="sing-in"><router-link :to="{name: 'signIn'}"><app-button title="Sign in"/></router-link></div>
      </div>
    </header>
    <!--header-->
    <!--.general-container-->
    <section class="general-container">
      <div class="container">
        <div class="congrats-box">
          <div class="congrats-box__img"><img src="../../assets/images/congrats.png" alt=""></div>
          <div class="congrats-box__txt">Congrats! You have just registered your account!</div>
          <div class="congrats-box__head">Check your email to complete the registration</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import Button from '../../components/Button.vue'
  export default {
    components: {
      'app-button': Button
    }
  }
</script>
