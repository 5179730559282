import axios from 'axios'
import ConfigHelper from '../../helpers/ConfigHelper'
import * as types from './mutationTypes'

const BASE_URL = ConfigHelper.get('apiUrl')
const CATEGORIES_URL = BASE_URL + '/categories'

const state = {
  isProcessing: false,
  fetchError: null,
  allCategories: [],
  locations: []
}

const getters = {
  allCategories (state) {
    return state.allCategories
  }
}

const actions = {
  fetchCategories ({commit, state}) {
    if (!state.allCategories.length) {
      commit(types.CATEGORIES_FETCH_PROCESSING)
      axios.get(CATEGORIES_URL).then((res) => {
        commit(types.CATEGORIES_FETCH_SUCCESS, res.data.data)
      }).catch(error => {
        commit(types.CATEGORIES_FETCH_ERROR, error)
      })
    }
  }
}

const mutations = {
  [types.CATEGORIES_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.CATEGORIES_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.allCategories = data
    return state
  },
  [types.CATEGORIES_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.error = error
    return state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
