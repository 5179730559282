import VeeValidate from 'vee-validate';

VeeValidate.Validator.extend('validate_location', {
  getMessage: (field) => `Location is invalid`,
  validate: (value) => new Promise(resolve => {
    resolve({
      valid: !!value.place_id
    })
  })
})

VeeValidate.Validator.extend('validate_no_email', {
  getMessage: (field) => `Email addresses are not allowed in ${field} field`,
  validate: (value) => new Promise(resolve => {
    const hasEmail = value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

    resolve({
      valid: hasEmail === null
    })
  })
})

VeeValidate.Validator.extend('validate_no_phone', {
  getMessage: (field) => `Phone numbers are not allowed in ${field} field`,
  validate: (value) => new Promise(resolve => {
    const hasPhone = value.match(/[+]?[(]?[0-9]{3}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4,6}$/im);

    resolve({
      valid: hasPhone === null
    })
  })
})

export default VeeValidate
