<template>
  <div class="pagination-box" v-if="totalItems > itemsPerPage">
    <a @click.prevent="pageChanged(1)" :class="[{disabled: this.currentPage === 1}, 'prev']">
      <span class="icon-ico-54-navigation-left"></span>First
    </a>
    <ul>
      <span v-if="paginationRange.indexOf(1) === -1" >
        <li  :class="[activePage(1), 'page-number']">
          <span @click.prevent="pageChanged(1)">{{ 1 }}</span>
        </li>
        <li v-if="paginationRange[0] !== 2">...</li>
      </span>
      <li v-for="n in paginationRange" :class="[activePage(n), 'page-number']">
        <span @click.prevent="pageChanged(n)">{{ n }}</span>
      </li>
      <span v-if="paginationRange.indexOf(lastPage) === -1" >
        <li v-if="paginationRange[paginationRange.length - 1] !== lastPage - 1">...</li>
        <li :class="[activePage(lastPage), 'page-number']">
          <span @click.prevent="pageChanged(lastPage)">{{ lastPage }}</span>
        </li>
      </span>

    </ul>
    <a @click.prevent="pageChanged(lastPage)" :class="[{disabled: this.currentPage === this.lastPage}, 'next']">
      Last<span class="icon-ico-55-navigation-right"></span>
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: Number,
      itemsPerPage: Number,
      totalItems: Number,
      visiblePages: {
        type: Number,
        default: 5,
        coerce: (val) => parseInt(val)
      },
      scrollToTop: {
        type: Function,
        default: () => {
          window.$('body').scrollTop(0)
        }
      }
    },
    data () {
      return {}
    },
    computed: {
      lastPage () {
        if (this.totalPages) {
          return this.totalPages
        } else {
          return this.totalItems % this.itemsPerPage === 0
            ? this.totalItems / this.itemsPerPage
            : Math.floor(this.totalItems / this.itemsPerPage) + 1
        }
      },
      paginationRange () {
        let start = this.currentPage - this.visiblePages / 2 <= 0
          ? 1 : this.currentPage + this.visiblePages / 2 > this.lastPage
            ? this.lowerBound(this.lastPage - this.visiblePages + 1, 1)
            : Math.ceil(this.currentPage - this.visiblePages / 2)
        let range = []
        for (let i = 0; i < this.visiblePages && i < this.lastPage; i++) {
          range.push(start + i)
        }
        return range
      }
    },
    methods: {
      pageChanged (pageNum) {
        this.$emit('page-changed', pageNum)
        this.scrollToTop()
      },
      activePage (pageNum) {
        return this.currentPage === pageNum ? 'active' : ''
      },
      lowerBound (num, limit) {
        return num >= limit ? num : limit
      }
    }
  }
</script>

<style>
  .page-number, .prev, .next {
    cursor: pointer;
  }
  .pagination-box .disabled {
    display: none;
  }
</style>
