export default {
  methods: {
    isCompany (profile) {
      if (profile.id && profile.role) {
        return profile.role.id === 1
      } else if (profile.role_id) {
        return parseInt(profile.role_id, 10) === 1
      }
    },

    isFreelancerProf (profile) {
      if (profile.id && profile.role) {
        return profile.role.id > 1
      } else if (profile.role_id) {
        return parseInt(profile.role_id, 10) > 1
      }
    },

    isLawyer (profile) {
      if (profile.id && profile.role) {
        return profile.role.id === 2
      } else if (profile.role_id) {
        return parseInt(profile.role_id, 10) === 2
      }
    },

    isNonLawyer (profile) {
      if (profile.id && profile.role) {
        return profile.role.id === 3
      } else if (profile.role_id) {
        return parseInt(profile.role_id, 10) === 3
      }
    }
  }
}
