<template>
  <div>
    <preloader/>
    <div>
      <header class="header clearfix">
        <div class="container">
          <div class="logo">
            <router-link to="/"><img src="../../assets/images/logo.svg" alt=""></router-link>
          </div>
          <div class="sing-in">
            <router-link :to="{name: 'signIn'}">
              <app-button title="Sign in"/>
            </router-link>
          </div>
        </div>
      </header>
      <section class="registration-home">
        <div class="">
          <div class="heading-section align-center">Registration</div>
          <div class="subtitle align-center">It’s quick and easy</div>
          <div class="registration-button-wrapper">
            <router-link :to="{name: 'registrationCompany'}">
              <div class="registration-button">
                <div class="registration-button__title">COMPANY</div>
                <div class="registration-button__subtitle">HIRING FOR A JOB</div>
              </div>
            </router-link>
            <router-link :to="{name: 'registrationFreelancer'}">
              <div class="registration-button">
                <div class="registration-button__title">FREELANCER</div>
                <div class="registration-button__subtitle">LOOKING FOR AN OPPORTUNITY</div>
              </div>
            </router-link>
          </div>
        </div>
      </section>
      <div class="above-footer"></div>
    </div>
  </div>
</template>

<script>
import Button from '../../components/Button.vue'
import Footer from '../../components/layout/Footer.vue'
import Preloader from '../../components/layout/Preloader.vue'

export default {
  components: {
    'app-footer': Footer,
    'preloader': Preloader,
    'app-button': Button,
  },
}
</script>

<style lang="scss">
@import "src/assets/styles/var_and_mixins";

.registration-home {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  min-height: calc(100vh - 255px);
  background: url('../../assets/images/registration/home.jpg') center center / cover no-repeat;

  & .heading-section {
    margin-bottom: 0;
    color: #000000;
  }

  & .subtitle {
    @include fr;
    font-size: 14px;
    margin-bottom: 20px;
    color: #000000;
    @media (min-width: 481px) {
      margin-bottom: 40px;
    }
  }
}

.registration-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.registration-button {
  @include fo;
  @include anim-fast;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #af9409;

  &__title {
    @include anim-fast;
    color: #af9409;
  }

  &__subtitle {
    @include anim-fast;
    color: #c0c0c0;
  }

  &:hover {
    background: #af9409;
    border: 1px solid #fcefb0;

    & .registration-button__title {
      color: #ffffff;
    }

    & .registration-button__subtitle {
      color: #fcefb0;
    }
  }
}
</style>
